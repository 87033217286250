import {useEffect} from 'react';
import {useMutation} from '@tanstack/react-query';
import useChatRestClient from '@/Api/ChatRestClient';
import {getLocalStorage} from '@/Utils/CommonUtils';

interface PostMessageHandlerProps {
  onUserVerified: (data: any) => void;
  onVerificationError?: (error: any) => void;
}

const PostMessageHandler: React.FC<PostMessageHandlerProps> = ({
  onUserVerified,
  onVerificationError,
  children = <></>,
}) => {
  const ChatRestClient = useChatRestClient();

  const mutation = useMutation(
    async (data: {
      mobile_number: string;
      country_code: string;
      apiKey: string;
      customer_id: number;
      origin: string;
      email: string;
      username: string;
      customer_name: string;
      sex: string;
      dob: string;
      fide_id: string;
      lic_name: string;
      cdc_name: string;
    }) => {
      console.log(
        'Calling third-party verification API using ChatRestClient...',
      );
      const {
        mobile_number,
        country_code,
        apiKey,
        customer_id,
        origin,
        email,
        username,
        customer_name,
        sex,
        dob,
        fide_id,
        lic_name,
        cdc_name,
      } = data;

      const response = await ChatRestClient.post(
        'auth/v1/extlogin',
        {
          mobile_number,
          country_code,
          // origin,
          email,
          username,
          customer_name,
          sex,
          dob,
          fide_id,
          lic_name,
          cdc_name,
        },
        {
          headers: {
            'CC-API-KEY': apiKey,
            'CC-CUSTOMER-ID': customer_id,
          },
          // bypassAuth: true,
        },
      );

      return response.data;
    },
    {
      onSuccess: data => {
        if (onUserVerified && data.success) {
          onUserVerified(data);
        }
      },
      onError: error => {
        console.error('Verification failed:', error);
        if (onVerificationError) onVerificationError(error);
      },
    },
  );

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      // Ensure the message is coming from the expected origin
      // const allowedOrigins = ['http://localhost:8080']; // Replace with your allowed origins
      // if (!allowedOrigins.includes(event.origin)) {
      //   console.error('Message from unauthorized origin:', event.origin);
      //   return;
      // }
      const parentOrigin = document?.referrer;
      console.log(
        'Message received: iframe',
        parentOrigin,
        event?.data?.type,
        event,
      );
      if (event.data && event.data.type !== 'third_party_message') {
        console.error('Unexpected message type: iframe', event?.data?.type);
        return;
      }

      const {payload} = event.data;
      if (
        payload &&
        payload?.mobile_number &&
        payload?.country_code &&
        payload.apiKey &&
        payload.customer_id
      ) {
        console.log(
          'Extracted message payload:',
          payload.mobile_number,
          payload.apiKey,
        );
        const access_token = await getLocalStorage('access_token');
        let userDetails = await getLocalStorage('user_details');
        userDetails = JSON.parse(userDetails);
        let loggedInMobileNumber = userDetails?.mobile_number?.replace('+', '');
        loggedInMobileNumber = loggedInMobileNumber?.slice(-10);

        let payloadMobileNumber = `${payload?.mobile_number}`?.replace('+', '');
        payloadMobileNumber = payloadMobileNumber?.slice(-10);
        console.log(
          loggedInMobileNumber,
          payloadMobileNumber,
          loggedInMobileNumber === payloadMobileNumber,
          'loggedInMobileNumber check',
        );
        if (loggedInMobileNumber === payloadMobileNumber) return;
        mutation.mutate({
          mobile_number: payload.mobile_number,
          country_code: payload?.country_code,
          apiKey: payload.apiKey,
          customer_id: payload.customer_id,
          origin: parentOrigin,
          email: payload.email,
          username: payload.username,
          customer_name: payload.customer_name,
          sex: payload.sex,
          dob: payload.dob,
          fide_id: payload.fide_id,
          lic_name: payload.lic_name,
          cdc_name: payload.cdc_name,
        });
      } else {
        console.error('Invalid message payload:', event.data);
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [mutation]);

  return children;
};

export default PostMessageHandler;
