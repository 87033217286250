import React, {useEffect, useMemo, useState, useRef} from 'react';
import {Switch, View, ScrollView, Image} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import CCColors from '@/Utils/CCColors';
import CustomRulesModal from '../CustomRulesModal';
import TimeControl from '../TimeControl';
import CustomConfig from '../CustomComponent/CustomConfig';
import ChallengeUserComponent from '../ChallengeUserComponent';
import GameLibraryComponent from '../GameLibraryComponent';
import ReviewGames from '../ReviewGamesComponent';
import GameOptionTile from '../GameOptionTiles';
import LearnTile from '../LearnTiles';
import ChallengeCaissaComponent from '../ChallengeCaissaComponent';
import useGetGameWithTag from '@/Hooks/GameLibraryApi/useGetGameWithTagApi';
import useCheckCircleChessGamesAnalyzedRule from '@/Hooks/SaveCircleChessGame/useCheckCircleChessGamesAnalyzedRule';
import {FontSizes, vs} from '@/Utils/Dimensions'; // Import necessary utilities
import {styles} from './styles';
import Divider from '@/Atoms/Divider/Divider';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

function classifyTimeControl(duration: number, increment: number) {
  const totalTime = (duration + increment) / 60000;

  if (totalTime < 3) {
    return 'Bullet';
  } else if (totalTime < 10) {
    return 'Blitz';
  } else if (totalTime < 30) {
    return 'Rapid';
  } else {
    return 'Classical';
  }
}

const timeControls = [
  {control: '1+0', icon: 'bullet', selected: true},
  {control: '3+2', icon: 'blitz', selected: false},
  {control: '5+3', icon: 'blitz', selected: false},
  {control: '10+5', icon: 'rapid', selected: false},
  {control: '15+10', icon: 'rapid', selected: false},
  {control: '25+10', icon: 'classical', selected: false},
  // { control: 'settings', icon: 'settings', selected: false }
];

function GameSearchComponent(props) {
  const {isStaff = false} = props;
  const {isMobileOrTab} = useMediaQuery();
  const [formValid, setFormValid] = useState(true);
  const [selectedControl, setSelectedControl] = useState({
    control: '3+2',
    icon: 'blitz',
    selected: false,
  });
  const [showCustomConfig, setShowCustomConfig] = useState(false);
  const [gameMode, setGameMode] = useState('Play');
  const [selectedGameOption, setSelectedGameOption] = useState('1');
  const [opponent, setOpponent] = useState('');
  const [fen, setFen] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );
  const [white, setWhite] = useState('');
  const [gameStage, setGameStage] = useState('');
  const [tag, setTag] = useState('');
  const [gameOptions, setGameOptions] = useState([
    {value: '0', label: 'Play against a random opponent'},
    {value: '1', label: 'Play a Circlechess user'},
    {value: '2', label: 'Pick position from Library'},
    {value: '3', label: 'Play against Caissa bot'},
  ]);
  const [gameWithTagFen, setGameWithTagFen] = useState('');
  const [color, setColor] = useState(2);
  const [isEnabled, setIsEnabled] = useState(false);

  const {mutate: GetGameWithTag, data: GetGameWithTagData} =
    useGetGameWithTag();
  const {mutate: CheckCircleChessGamesAnalyzedRule} =
    useCheckCircleChessGamesAnalyzedRule();

  const {
    joinGame,
    challengeGame,
    searchPlayers,
    searchPlayersResult,
    lastTimecontrol,
    // timeControls,
    showReviewGamesComponent,
    user,
    gamePlayMode,
    customRules,
    setAssistedGameplay,
    setGameDetails,
    setGamePlayMode,
    enableLearn,
  } = props;

  useEffect(() => {
    if (gamePlayMode === 'Play') {
      handlePlayMode();
    } else if (gamePlayMode === 'Play and Learn') {
      handleLearnMode();
    }
  }, [gamePlayMode]);

  const scrollViewRef = useRef();

  // Function to scroll to the bottom of the ScrollView
  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
    console.log('Scroll to bottom');
  };

  useEffect(() => {
    if (
      (selectedGameOption === '1' || selectedGameOption === '3') &&
      opponent?.length === 0
    ) {
      setFormValid(false);
    } else if (
      selectedGameOption === '2' &&
      (tag?.length === 0 || opponent?.length === 0)
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
      scrollToBottom();
    }
  }, [selectedGameOption, opponent, tag]);

  const handlePlayMode = () => {
    setGameMode('Play');
    setGamePlayMode('Play');
    setGameOptions([
      {value: '0', label: 'Play against a random opponent'},
      {value: '1', label: 'Play a Circlechess user'},
      {value: '2', label: 'Pick position from Library'},
      {value: '3', label: 'Play against Caissa bot'},
    ]);
    setAssistedGameplay(false);
    selectControl('5+3');
  };

  const handleLearnMode = () => {
    setGameMode('Play and Learn');
    setGamePlayMode('Play and Learn');
    setGameOptions([
      {value: '2', label: 'Pick position from Library'},
      {value: '3', label: 'Play against Caissa bot'},
    ]);
    setAssistedGameplay(true);
    setSelectedControl({control: '60 + 15'});
    setSelectedGameOption('2');
  };

  const selectControl = (control: string) => {
    const updatedControls = timeControls?.map(tc => ({
      ...tc,
      selected: tc.control === control,
    }));
    setSelectedControl(
      updatedControls?.find(tc => tc.control === control) ||
        updatedControls?.[0],
    );
  };

  const extractTimeControls = () => {
    const timeString = selectedControl?.control;
    const [minutes, seconds] = timeString?.split('+')?.map(Number);
    return {moveTimer: minutes * 60000, increment: seconds * 1000};
  };

  const handleFindOpponent = () => {
    const timeControl = extractTimeControls();
    let rated = isPlaySelected ? 'Rated' : 'Unrated';
    if (opponent?.startsWith('0:Caissa') || selectedGameOption === '2') {
      rated = 'Unrated';
    }
    setGameDetails(
      `${timeControl.moveTimer / 60000} + ${
        timeControl.increment / 1000
      } • ${rated} • ${classifyTimeControl(
        timeControl.moveTimer,
        timeControl.increment,
      )}`,
    );

    switch (selectedGameOption) {
      case '0':
        joinGame(timeControl.moveTimer, timeControl.increment);
        break;
      case '1':
      case '3':
        challengeGame(
          opponent,
          timeControl.moveTimer,
          timeControl.increment,
          white,
          fen,
        );
        break;
      case '2':
        GetGameWithTag({tag_name: tag, game_stage: gameStage});
        break;
    }
  };

  useEffect(() => {
    if (GetGameWithTagData && GetGameWithTagData.fen) {
      setGameWithTagFen(GetGameWithTagData.fen);
    }
  }, [GetGameWithTagData]);

  useEffect(() => {
    const timeControl = extractTimeControls();

    // console.log('inside gameWithTagFen useEffect');

    if (gameWithTagFen != '') {
      challengeGame(
        opponent,
        timeControl.moveTimer,
        timeControl.increment,
        white,
        gameWithTagFen,
      );
    }
  }, [gameWithTagFen]);

  useEffect(() => {
    setWhite(color === 1 ? user : color === 0 ? opponent : '');
  }, [color, opponent]);

  const isPlaySelected = useMemo(() => gameMode === 'Play', [gameMode]);
  return (
    <Box
      style={[
        styles.rightPaneContainer,
        isMobileOrTab ? {flex: 1} : {flex: 0.5},
      ]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        style={styles.scrollView}>
        <Box>
          {/* Game mode selection */}
          <Box style={styles.playModeContainer}>
            <Image
              source={require('@/Assets/Images/Caissa/Caissa_bot_horse.svg')}
              style={[
                styles.buttonIcon,
                // styles.chessPatternLeft,
                {zIndex: 3, transform: [{scaleX: 1}]},
                isMobileOrTab && {
                  height: '120%',
                },
              ]}
              // resizeMethod="scale"
              resizeMode="contain"
            />
            <Box
              onPress={handlePlayMode}
              style={[
                styles.playButtonStyle,
                styles.playButtonStyleLeft,
                isPlaySelected && styles.playModeContainerSelected,
              ]}>
              {isPlaySelected && (
                <Image
                  source={require('@/Assets/Images/Caissa/aqua_chess_pattern.svg')}
                  style={[styles.chessPattern, styles.chessPatternLeft]}
                  // resizeMethod="scale"
                  // resizeMode="stretch"
                />
              )}

              <CCText
                style={[
                  styles.playButtonTxtStyle,
                  isPlaySelected && {color: CCColors.White},
                ]}>
                Play
              </CCText>
              {isPlaySelected && (
                <Image
                  source={require('@/Assets/Images/Caissa/aqua_chess_pattern.svg')}
                  style={[
                    styles.chessPattern,
                    styles.chessPatternRight,
                    // !isPlaySelected && styles.playModeContainerSelected,
                  ]}
                  // resizeMethod="scale"
                  // resizeMode="stretch"
                />
              )}
            </Box>
            <Box
              onPress={handleLearnMode}
              disabled={!isStaff}
              style={[
                styles.playButtonStyle,
                styles.playButtonStyleRight,
                !isPlaySelected && styles.playModeContainerSelected,
              ]}>
              <Image
                source={require('@/Assets/Images/Caissa/Caissa_bot_girl.svg')}
                style={[
                  styles.buttonGirlIcon,
                  // styles.chessPatternLeft,
                  {zIndex: 3, transform: [{scaleX: 1}]},
                  isMobileOrTab && {
                    height: '120%',
                    right: 0,
                  },
                ]}
                // resizeMethod="scale"
                resizeMode="contain"
              />
              {!isPlaySelected && (
                <Image
                  source={require('@/Assets/Images/Caissa/aqua_chess_pattern.svg')}
                  style={[styles.chessPattern, styles.chessPatternLeft]}
                  // resizeMethod="scale"
                />
              )}
              <Box
                style={[
                  styles.playButtonTxtStyle,
                  ,
                  {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}>
                <CCText style={[!isPlaySelected && {color: CCColors.White}]}>
                  Play and Learn
                </CCText>
                {/* {!isStaff && (
                  <CCText
                    style={[
                      // styles.playButtonTxtStyle,
                      !isPlaySelected && {color: CCColors.White},
                    ]}>
                    (coming soon)
                  </CCText>
                )} */}
              </Box>

              {!isPlaySelected && (
                <Image
                  source={require('@/Assets/Images/Caissa/aqua_chess_pattern.svg')}
                  style={[styles.chessPattern, styles.chessPatternRight]}
                  // resizeMethod="scale"
                />
              )}
            </Box>
            {!isStaff && (
              <CCText
                style={[
                  // styles.playButtonTxtStyle,
                  !isPlaySelected && {color: CCColors.White},
                  {
                    position: 'absolute',
                    top: '-42%',
                    zIndex: 2,
                    right: '18%',
                    color: CCColors.Primary.Aqua,
                  },
                ]}>
                (coming soon)
              </CCText>
            )}
            {/* <Box onPress={handlePlayMode} style={styles.playModeBox}>
              <Image
                source={
                  isPlaySelected
                    ? require('@/Assets/Images/Caissa/aqua_chess_pattern.svg')
                    : require('@/Assets/Images/Svgs/Play normal.svg')
                }
                style={styles.playIcon}
                // resizeMethod="scale"
                resizeMode="stretch"
              />
            </Box>
            {!enableLearn && (
              <Box style={styles.playModeBox}>
                <Image
                  source={
                    isPlaySelected
                      ? require('@/Assets/Images/Svgs/Play and Learn normal.svg')
                      : require('@/Assets/Images/Svgs/Play and Learn active.svg')
                  }
                  style={styles.playIcon}
                  resizeMode="contain"
                />
                <View style={styles.overlay} />
                <CCText style={styles.comingSoonText}>Coming Soon...</CCText>
              </Box>
            )}
            {enableLearn && (
              <Box onPress={handleLearnMode} style={styles.playModeBox}>
                <Image
                  source={
                    isPlaySelected
                      ? require('@/Assets/Images/Svgs/Play and Learn normal.svg')
                      : require('@/Assets/Images/Svgs/Play and Learn active.svg')
                  }
                  resizeMode="contain"
                  style={styles.playIcon}
                />
              </Box>
            )} */}
          </Box>

          {/* Game Options */}
          {isPlaySelected && (
            <Box style={styles.gameOptionContainer}>
              {gameOptions?.map(e => (
                <GameOptionTile
                  key={e.value}
                  flex={0.3}
                  GameOption={e.value}
                  gameOptionText={e.label}
                  selected={selectedGameOption === e.value}
                  onClick={() => {
                    setSelectedGameOption(e.value);
                    setOpponent('');
                  }}
                />
              ))}
            </Box>
          )}

          {/* Learn Mode */}
          {gameMode === 'Play and Learn' && (
            <>
              <CCText style={styles.learnModeText}>Learn while you Play</CCText>
              <CCText style={styles.learnModeDescription}>
                Play without any time pressure and with gameplay assistance
              </CCText>
              <Box style={styles.gameOptionContainer}>
                {gameOptions?.map(e => (
                  <LearnTile
                    key={e.value}
                    flex={0.3}
                    GameOption={e.value}
                    gameOptionText={e.label}
                    selected={selectedGameOption === e.value}
                    onClick={() => setSelectedGameOption(e.value)}
                  />
                ))}
              </Box>
            </>
          )}

          {/* Additional Components */}
          {!showReviewGamesComponent && selectedGameOption === '1' && (
            <ChallengeUserComponent
              handleOpponentName={setOpponent}
              searchPlayers={searchPlayers}
              searchPlayersResult={searchPlayersResult}
              user={user}
            />
          )}
          {!showReviewGamesComponent && selectedGameOption === '2' && (
            <GameLibraryComponent
              handleSelectedGameStage={setGameStage}
              handleSelectedTag={setTag}
              handleOpponentName={setOpponent}
              searchPlayers={searchPlayers}
              searchPlayersResult={searchPlayersResult}
              user={user}
              gameMode={gameMode}
            />
          )}
          {!showReviewGamesComponent && selectedGameOption === '3' && (
            <ChallengeCaissaComponent
              handleOpponentName={setOpponent}
              user={user}
            />
          )}
          {/* {gameMode == 'Play' && (
            <Box
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              style={[filterStyles.containerHeading, {zIndex: -20}]}>
              {timeControls.map(e => (
                <TimeControl
                  flex={0.3}
                  control={e.control}
                  icon={e.icon}
                  selected={selectedControl.control === e.control}
                  onClick={() => selectControl(e.control)}
                />
              ))}
            </Box>
          )} */}
          {/* Time Controls */}
          {isPlaySelected && (
            <>
              <CCText style={styles.timeControlTitle}>Time Controls</CCText>
              {/* {console.log(JSON.stringify(timeControls), 'timeControls')} */}
              <Box style={styles.timeControlContainer}>
                {timeControls?.map(e => (
                  <TimeControl
                    key={e.control}
                    flex={0.3}
                    control={e.control}
                    icon={e.icon}
                    selected={selectedControl?.control === e.control}
                    onClick={() => selectControl(e.control)}
                  />
                ))}
              </Box>
            </>
          )}
          <Divider marginTop={25} marginBottom={25} style={{zIndex : -20}} />
          {/* Additional Settings */}
          <Box style={styles.additionalSettingsContainer}>
            <Box
              style={styles.switchContainer}
              onPress={() => setShowCustomConfig(!showCustomConfig)}>
              <Ionicons
                name={showCustomConfig ? 'options' : 'options-outline'}
                size={20}
                color={CCColors.Green}
              />
              <CCText
                style={styles.additionalSettingsText}
                fontSize={FontSizes[14]}>
                Show additional settings
              </CCText>
            </Box>
            {/* <Box style={styles.switchContainer}>
              <CCText style={styles.additionalSettingsText}>
                Additional Settings
              </CCText>
              <Switch
                trackColor={{false: '#767577', true: CCColors.Green}}
                thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => setShowCustomConfig(!showCustomConfig)}
                value={showCustomConfig}
              />
            </Box>
            <CustomRulesModal /> */}
            <CustomRulesModal />
          </Box>

          {/* Custom Config */}
          {showCustomConfig && (
            <CustomConfig
              handleFen={setFen}
              handleColor={setColor}
              selectedGameOption={selectedGameOption}
            />
          )}

          {/* Play Button */}
          {!showReviewGamesComponent && (
            <View style={styles.playButtonContainer}>
              <CCButton onPress={handleFindOpponent} disabled={!formValid}>
                <CCText style={styles.playButtonText}>Play</CCText>
              </CCButton>
            </View>
          )}
          {showReviewGamesComponent && <ReviewGames />}
        </Box>
      </ScrollView>
    </Box>
  );
}

export default GameSearchComponent;
