import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
} from 'react-native';
import {vs} from '../../Utils/Dimensions';
import CCColors from '@/Utils/CCColors';
import CCCheckbox from '../CCCheckbox';
import SlideModal from '../SlideModal';
import CCButton from '../CCButton';
import {Picker} from '@react-native-picker/picker';
import CCText from '../CCText';
import Box from '../Box';
import CCLoader from '../CCLoader';
import Spacer from '../Spacer';

interface DropdownItem {
  label: string;
  value: string;
}

interface CCSelectProps {
  label?: string;
  modalLabel?: string;
  selectedValue?: string;
  showModal?: boolean;
  selectedValues?: string[];
  onValueChange: (values: string[]) => void;
  items: DropdownItem[];
  headerComponent: JSX.Element;
  placeholder?: string;
  editable?: boolean;
  loading?: boolean;
  style?: {};
  hideBorder?: boolean;
  ccSelectHeight?: number;
  multiSelect?: boolean;
}

const CCSelect: React.FC<CCSelectProps> = ({
  label,
  modalLabel,
  headerComponent,
  loading,
  selectedValue,
  selectedValues = [],
  showModal = false,
  onValueChange,
  items,
  placeholder = 'Select an option',
  editable = true,
  style: selectStyle,
  hideBorder = false,
  ccSelectHeight = 40,
  multiSelect = false,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSelectedValues, setTempSelectedValues] =
    useState<string[]>(selectedValues);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleValueChange = (value: string) => {
    if (multiSelect) {
      const newValue = tempSelectedValues.includes(value)
        ? tempSelectedValues.filter(item => item !== value)
        : [...tempSelectedValues, value];
      setTempSelectedValues(newValue);
    } else {
      if (showModal) {
        setTempSelectedValues([value]);
      } else {
        onValueChange([value]);
        setDropdownVisible(false);
      }
    }
  };

  const handleApply = () => {
    onValueChange(tempSelectedValues);
    setDropdownVisible(false);
  };

  const clearOptions = () => {
    onValueChange([]);
    setTempSelectedValues([]);
    setDropdownVisible(false);
  };
  const filteredItems = items.filter(item =>
    item?.label?.toLowerCase().includes(searchQuery?.toLowerCase()),
  );

  const handleOutsideClick = () => {
    setDropdownVisible(false);
  };

  if (!multiSelect && !showModal) {
    return (
      <View style={styles.container}>
        <Text style={styles.label}>{label}</Text>
        <Picker
          itemStyle={{
            margin: '5%',
          }}
          enabled={editable || loading}
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          style={[selectStyle, styles.dropdown, {height: ccSelectHeight}]}>
          {!selectedValue && <Picker.Item label={placeholder} value="" />}
          {items.map((item, index) => (
            <Picker.Item key={index} label={item.label} value={item.value} />
          ))}
        </Picker>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <CCText style={styles.label}>{label}</CCText>
      <TouchableOpacity
        style={[
          selectStyle,
          styles.dropdown,
          hideBorder && styles.hideBorder,
          {height: ccSelectHeight},
        ]}
        onPress={toggleDropdown}>
        <CCText numberOfLines={2}>
          {selectedValues.length > 0
            ? items
                .filter(item => selectedValues.includes(item.value))
                .map(item => item.label)
                .join(', ')
            : placeholder}
        </CCText>
      </TouchableOpacity>
      <SlideModal
        modalHeaderTitle={modalLabel}
        visible={dropdownVisible}
        type="side"
        onClose={handleOutsideClick}
        footerComponent={
          <Box type="row-center-between">
            <CCButton
              type="box"
              style={{
                backgroundColor: CCColors.White,
                borderColor: CCColors.Primary.Brown,
              }}
              titleStyle={{color: CCColors.TextColor.Primary}}
              onPress={clearOptions}>
              Clear
            </CCButton>
            <CCButton
              type="box"
              onPress={handleApply}
              titleStyle={{color: CCColors.White}}>
              Apply
            </CCButton>
          </Box>
        }>
        <View style={styles.dropdownContentContainer}>
          <TextInput
            style={styles.searchInput}
            placeholder="Search..."
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
          {headerComponent && <Box margin={10}>{headerComponent}</Box>}
          <CCLoader loading={loading} isComponentLoader />
          <ScrollView style={styles.dropdownContent}>
            {filteredItems.map((item, index) => (
              <TouchableOpacity
                key={index}
                style={styles.dropdownItem}
                onPress={() => handleValueChange(item.value)}>
                {(multiSelect || showModal) && (
                  <CCCheckbox
                    value={tempSelectedValues.includes(item.value)}
                    onValueChange={() => handleValueChange(item.value)}
                  />
                )}
                <CCText>{item.label}</CCText>
              </TouchableOpacity>
            ))}
            <Spacer spacing={60} />
          </ScrollView>
        </View>
      </SlideModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: vs(0),
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  dropdown: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  hideBorder: {
    backgroundColor: CCColors.White,
    borderWidth: 0,
    borderColor: CCColors.White,
  },
  dropdownContentContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    height: '100%',
  },
  searchInput: {
    padding: 10,
    borderBottomWidth: 1,
    borderColor: CCColors.Grey.Grey,
  },
  dropdownContent: {
    flex: 1,
  },
  dropdownItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

export default CCSelect;
