import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  mobileContainer: {
    flex: 1,
    // height: '90vh',
    // justifyContent: 'center',
    // alignItems: 'baseline',
  },
  container: {
    flexDirection: 'row', // to align chessboard and moves list side by side
    // flex: 1,
    width: '100%',
    // overflow: 'scroll',
    height: '100%',
    // height: WINDOW_HEIGHT / 1.25,
    // padding: vs(50),
  },
  NotesBox: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderRadius: 5,
    padding: 10,
  },
  manualMovesContainer: {
    backgroundColor: CCColors.White,
    borderRadius: 5,
    marginBottom: 20,
    width: '70%',
    padding: 10,
  },
  selectedManualMove: {
    color: CCColors?.Primary?.Default,
    borderBottomWidth: 1,
    borderBottomColor: CCColors?.Primary?.Default,
  },
  Leftcontainer: {
    flex: 1,
    paddingTop: vs(25),
    paddingBottom: vs(5),
    paddingLeft: vs(15),
    // paddingTop: vs(15),
  },
  gameCategoryTitle: {},
  gameCategorySubTitle: {},
  winningBar: {
    // width: '50%',
    width: 25,
    // margin: 'auto',
    justifyContent: 'center',
  },
  winningBarMobile: {
    // width: '50%',
    width: 'auto',
  },
  chessboard: {
    // width: '50%',
    flex: 2,
    // alignItems: 'center', // Center the chessboard horizontally
    // justifyContent: 'center',
    // maxHeight: '90vh',
  },
  eachPlayerName: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  eachPlayerDetails: {},
  BrownBox: {
    width: 15,
    height: 15,
    backgroundColor: CCColors.Primary.Brown,
    borderColor: CCColors.Primary.Brown,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2,
  },
  whiteBox: {
    width: 15,
    height: 15,
    backgroundColor: CCColors.White,
    borderColor: CCColors.Black,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2,
  },
  movesList: {
    // width: 300, // Adjust width as needed
    // marginLeft: 10,
    flex: 1.5,
    // maxWidth: 450,
    backgroundColor: '#FAF6EB',
    // maxHeight: WINDOW_HEIGHT / 1.1,
    // width: '100%',
    // flex: 1,
  },
  movesListMobile: {
    flex: 1,
  },
  move: {
    padding: 5,
  },
  currentMove: {
    backgroundColor: '#e0e0e0',
  },

  blunder: {
    color: 'red',
  },

  mistake: {
    color: 'orange',
  },

  inaccuracy: {
    color: '#c3c34e',
  },

  playerDetails: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // marginVertical: vs(15),
  },

  profilePic: {
    width: vs(40),
    height: vs(40),
    borderRadius: 20,
    backgroundColor: CCColors?.Grey?.Grey,
    marginRight: vs(15),
  },
  name: {},
  rating: {},
  chessboardTable: {
    // width: '100%',
    // flex: 1,
    // flexBasis: '100%',
    // overflow: 'hidden',
    flexDirection: 'row',
  },
  chessboardTableMobile: {
    flexDirection: 'column-reverse',
  },
});

export default styles;
