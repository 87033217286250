import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCTextInput from '@/Atoms/CCTextInput';
import SlideModal from '@/Atoms/SlideModal';
import Spacer from '@/Atoms/Spacer';
import CCColors from '@/Utils/CCColors';
import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {Chess, validateFen} from 'chess.js';
import useCreateStudyGame from '@/Hooks/CreateNewStudyApi/useCreateStudyGame';
import {useNavigation} from '@react-navigation/native';
import Loader from '@/Atoms/CCLoader/CCLoader';
import ChipList from '@/Atoms/ChipList/ChipList';
import CCText from '@/Atoms/CCText';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {newChapterDetailsType} from './types';
import CCSelect from '@/Atoms/CCSelect';
import {ScreenName} from '@/navigators/StackConstants';
import useCreateNewChapter from '@/Hooks/CreateNewChapterApi/useCreateNewChapter';

const newStudyTypes = [
  {key: 'FEN', value: 'FEN'},
  {key: 'PGN', value: 'PGN'},
  {key: 'Start', value: 'Start'},
];
// const [types, setTypes] = useState([
//     {key: 'Classical', value: 'Classical'},
//     {key: 'Rapid', value: 'Rapid'},
//     {key: 'Blitz', value: 'Blitz'},
//   ]);

//   const [typeSelected, selectType] = useState({
//     key: 'Classical',
//     value: 'Classical',
//   });

function CreateNewStudy({
  createChapterOnly = false,
  successCallback,
  overrideButton,
  studySlug,
}: {
  createChapterOnly?: boolean;
  successCallback?: () => void;
  overrideButton?: (func: () => void) => any;
  studySlug?: string;
}) {
  const navigation = useNavigation();
  const [openNewStudyModal, setOpenNewStudyModal] = useState(false);
  const [error, setError] = useState({flag: false, errorMsg: ''});
  //   const [fen, setFen] = useState({
  //     error: false,
  //     fenVal: '',
  //     errorMsg: '',
  //   });
  const [newChapterDetails, setNewChapterDetails] =
    useState<newChapterDetailsType>({
      name: '',
      types: null,
      fen: '',
      pgn: '',
    });
  const [stage, setStage] = useState<1 | 2>(createChapterOnly ? 2 : 1);
  const [orientation, setOrientation] = useState<'White' | 'Black'>('White');
  const [newStudyDetails, setNewStudyDetails] = useState<{
    name: string;
    chapterName: string;
    pgn: string;
    type:
      | {key: 'FEN'; value: 'FEN'}
      | {key: 'PGN'; value: 'PGN'}
      | {key: 'Start'; value: 'Start'}
      | null;
  }>({
    name: '',
    pgn: '',
    type: null,
  });

  const {
    mutate: createNewStudy,
    data: newStudySuccess,
    error: newStudyError,
  } = useCreateStudyGame();

  const {
    mutate: createNewChapter,
    data: newChapterSuccess,
    error: newChapterError,
  } = useCreateNewChapter();

  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    if (newStudyError || newChapterError) {
      setError({flag: true, errorMsg: ''});
      setLoaderState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStudyError, newChapterError]);

  useEffect(() => {
    if (createChapterOnly) {
      setStage(2);
    }
  }, [createChapterOnly]);

  useEffect(() => {
    if (newStudySuccess?.success) {
      handleNewStudy(newStudySuccess);
      setLoaderState(false);
    } else if (newChapterSuccess?.success) {
      successCallback?.();
      setLoaderState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStudySuccess, newChapterSuccess]);

  const handleNewStudy = newGameDetails => {
    const studyGameSlug = newGameDetails?.data?.slug;
    navigation.navigate(ScreenName.StudyDetails, {
      study_id: studyGameSlug,
    });
  };

  const inputByTypes = useMemo(() => {
    switch (newChapterDetails?.types?.key) {
      case 'FEN':
        return (
          <CCTextInput
            // fontSize={14}
            value={newChapterDetails?.fen}
            style={{
              marginTop: 20,
            }}
            error={{flag: error?.flag, msg: error?.errorMsg || 'Invalid FEN '}}
            label="Enter a Fen"
            onChangeText={text => {
              setNewChapterDetails(prev => ({...prev, fen: text}));
              setError({flag: false, errorMsg: ''});
            }}
            placeholderTextColor={CCColors.TextColor.Grey}
          />
        );

      case 'PGN':
        return (
          <CCTextInput
            // fontSize={14}
            value={newChapterDetails?.pgn}
            style={{
              marginTop: 20,
            }}
            error={{flag: error?.flag, msg: error?.errorMsg || 'Invalid PGN '}}
            autoFocus
            label="Enter a PGN"
            onChangeText={text => {
              setNewChapterDetails(prev => ({
                ...prev,
                pgn: text?.replace(/\[.*?\]\s*/g, ''),
              }));
              setError({flag: false, errorMsg: ''});
            }}
            placeholderTextColor={CCColors.TextColor.Grey}
          />
        );

      default:
        return <></>;
    }
  }, [newChapterDetails, error]);

  const handleNext = () => {
    setStage(prev => (prev === 1 ? 2 : 1));
  };

  const resetStates = () => {
    setOpenNewStudyModal(false);
    setError({flag: false, errorMsg: ''});
    setNewChapterDetails({name: '', types: null, fen: '', pgn: ''});
    if (!createChapterOnly) {
      setStage(1);
    }
    setOrientation('White');
    setNewStudyDetails({
      name: '',
      pgn: '',
      type: null,
    });
  };

  const handleFenSubmit = () => {
    // const result = chess.validate_fen(fen?.fenVal);
    let startingPos = {};
    try {
      setLoaderState(true);
      if (newChapterDetails?.types?.key === 'FEN') {
        const validatePiece = validateFen(newChapterDetails?.fen);
        if (!validatePiece?.ok) {
          throw new Error(validatePiece?.error);
        }
        startingPos = {fen: newChapterDetails?.fen};
      } else if (newChapterDetails?.types?.key === 'PGN') {
        const chess = new Chess();
        const chessResultTemp = chess.loadPgn(newChapterDetails?.pgn);
        startingPos = {pgn: newChapterDetails?.pgn};
      } else {
        startingPos = {
          fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
        };
      }
      console.log('The FEN is valid.');

      if (createChapterOnly) {
        createNewChapter({
          name: newChapterDetails?.name,
          ...startingPos,
          orientation: orientation === 'Black' ? 'B' : 'W',
          slug: studySlug,
        });
      } else {
        createNewStudy({
          name: newStudyDetails?.name,
          chapter: {
            name: newChapterDetails?.name,
            ...startingPos,
            orientation: orientation === 'Black' ? 'B' : 'W',
          },
        });
      }
    } catch (error) {
      console.log(error, 'error');
      setLoaderState(false);
      setError({flag: true, errorMsg: `${error}`});
    }
  };

  const stage1 = (
    <Box type="center" margin={20}>
      <CCTextInput
        // fontSize={14}
        value={newStudyDetails?.name}
        style={{
          marginTop: 20,
        }}
        // error={{flag: fen?.error, msg: fen?.errorMsg || 'Invalid fen '}}
        autoFocus
        label="Workbook name"
        onChangeText={text => {
          setNewStudyDetails(prev => ({...prev, name: text}));
          //   setFen({error: false, fenVal: text, errorMsg: ''});
        }}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={20} />
      <CCButton
        onPress={handleNext}
        disabled={newStudyDetails?.name?.length <= 3}>
        <CCText>Next</CCText>
        <Spacer spacing={10} horizontal />
        <AntDesign
          name="arrowright"
          size={20}
          color={CCColors?.Primary?.Brown}
        />
      </CCButton>
    </Box>
  );

  const stage2 = (
    <Box type="center" margin={20}>
      <CCTextInput
        // fontSize={14}
        value={newChapterDetails?.name}
        style={{
          marginTop: 20,
        }}
        // error={{flag: fen?.error, msg: fen?.errorMsg || 'Invalid fen '}}
        autoFocus
        label="Chapter name"
        onChangeText={text => {
          setNewChapterDetails(prev => ({...prev, name: text}));
          // setFen({error: false, fenVal: text, errorMsg: ''});
        }}
        placeholderTextColor={CCColors.TextColor.Grey}
      />
      <Spacer spacing={20} />
      <CCSelect
        hideBorder
        // style={{
        //   backgroundColor: CCColors.White,
        //   width: hs(38),
        //   height: vs(50),
        //   borderColor: CCColors.White,
        // }}
        label="Orientation"
        // placeholder="Black or White"
        selectedValue={orientation}
        items={[
          {label: 'White', value: 'White'},
          {label: 'Black', value: 'Black'},
        ]}
        onValueChange={code => {
          setOrientation(code);
        }}
      />
      <Spacer spacing={20} />
      <ChipList
        onChange={element => {
          setNewChapterDetails(prev => ({
            ...prev,
            types: element,
          }));
        }}
        options={newStudyTypes}
        value={newChapterDetails?.types}
        horizontal={true}
      />
      {inputByTypes}
      <Spacer spacing={20} />
      <CCButton onPress={handleFenSubmit}>Create</CCButton>
    </Box>
  );

  const newStudyStages = useCallback(() => {
    switch (stage) {
      case 1:
        return stage1;

      case 2:
        return stage2;

      default:
        return stage1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, newStudyDetails, newChapterDetails, orientation, error]);

  return (
    <>
      <Loader loading={loaderState} />
      <SlideModal
        type="center"
        // width={0.3}
        modalHeaderTitle={stage === 1 ? 'New workbook' : 'New Chapter'}
        visible={openNewStudyModal}
        onClose={() => {
          setOpenNewStudyModal(false);
          if (!createChapterOnly) {
            setStage(1);
          }
        }}>
        {newStudyStages()}
      </SlideModal>
      <Spacer spacing={10} />
      <Box type="row-flex-end">
        {overrideButton ? (
          overrideButton(() => setOpenNewStudyModal(true))
        ) : (
          <CCButton onPress={() => setOpenNewStudyModal(true)}>
            + Start new workbook
          </CCButton>
        )}
      </Box>
    </>
  );
}

export default CreateNewStudy;
