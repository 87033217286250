import Environment from '../../environment';
import axios from 'axios';
import {onFulfilled} from './interceptors/request';
import {handleLogout} from '@/Utils/CommonUtils';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';

const ChessRestClient = axios.create({
  baseURL: Environment.CHESS_API_URL,
});
ChessRestClient.interceptors.request.use(onFulfilled);

ChessRestClient.interceptors.response.use(
  response => {
    // If the request was successful, just forward the response
    return response;
  },
  error => {
    // const navigation = useNavigation<any>();
    // If request was sent and server responded with a status outside of the 2xx range
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Handle 401 error code.
          handleLogout(() => {
            window.location.href = '/login';
            // navigation?.navigate(ScreenName?.Login);
          });
          console.error('401 Unauthorized Error');
          break;
        case 403:
          handleLogout(() => {
            window.location.href = '/login';
            // navigation?.navigate(ScreenName?.Login);
          });
          // Handle 403 error code.
          console.error('403 Forbidden Error');
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default ChessRestClient;
