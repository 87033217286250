import {
  FontSizes,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  hs,
  isPhone,
  vs,
} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    height: WINDOW_HEIGHT,
    width: WINDOW_WIDTH,
    flex: 1,
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
  },
  logoutButton: {
    width: 'fit-content',
    position: 'absolute',
    top: 0,
    right: 20,
  },
  logo: {
    width: isPhone ? vs(200) : vs(162),
    height: 32,
    marginVertical: 20,
    marginHorizontal: 'auto',
  },
  signInTypo: {
    textAlign: 'right',
    left: '50%',
    top: 48,
    color: CCColors?.Primary?.Default,
    position: 'absolute',
  },
  labelText: {
    // width: '100%',
    height: '100%',
    // justifyContent: 'center',
    flex: 0.4,
  },
  rightlabelText: {
    paddingLeft: isPhone ? vs(10) : vs(20),
    flex: 1,
    // height: '30%',
    // marginTop: '6%',
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
    color: CCColors.TextColor.Primary,
  },
  buttonWrapper: {
    backgroundColor: '#FACF47',
    borderColor: '#262322',
    borderRadius: 8,
    // height: vs(48),

    ...(isPhone
      ? {width: '98%'}
      : {
          width: vs(240),
        }),
  },
  buttonWrapper2: {
    backgroundColor: '#FAF6EB',
    borderColor: '#262322',
    ...(isPhone && {width: '70%', margin: 'auto'}),
  },
  textBoxes: {
    height: 48,
    width: 320,
    borderRadius: 8,
    borderColor: CCColors.TextBoxes.Borders,
    borderWidth: 1,
  },
});
