import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flexDirection: isPhone ? 'column' : 'row',
    flex: 1,
    overflow: 'scroll',
  },
  mobileContainer: {
    flex: 1,
  },
  left: {
    flex: 0.2,
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  center: {flex: 0.5, alignItems: 'center', justifyContent: 'center'},
  right: {flex: 0.3, padding: 10},
  addNewBox: {
    backgroundColor: CCColors.Primary.Brown,
    padding: 10,
    borderRadius: 8,
  },
  disabled: {
    backgroundColor: CCColors.Grey.Shade200,
    padding: 10,
    borderRadius: 8,
  },
});
