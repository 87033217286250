import Box from '@/Atoms/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {styles} from './styles';
// import ChessboardComponent from 'chessboard-package';
import ChessboardComponent from 'chessboard-package';
import {GameDetailsType} from '@/Hooks/GetUserOTBGames/types';
import {ScrollView} from 'react-native-gesture-handler';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import Spacer from '@/Atoms/Spacer';
import Feather from 'react-native-vector-icons/Feather';
import CCColors from '@/Utils/CCColors';
import {useNavigation, useRoute} from '@react-navigation/native';
// import {useGetUserOTBGames} from '@/Hooks/GetUserOTBGames/useGetUserOTBGames';
import {FontSizes, SCREEN_WIDTH, WINDOW_HEIGHT} from '@/Utils/Dimensions';
import useGetUserOTBGames from '@/Hooks/GetUserOTBGames/useGetUserOTBGames';
import useGetOTBGameDetails from '@/Hooks/GetOTBGameDetails/useGetOTBGameDetails';
import useSaveOTBGamePGN from '@/Hooks/SaveOTBGamePGNApi/useSaveOTBGamePGN';
import {generateMovesWithFen, generatePGN} from '@/Utils/CommonUtils';
import SlideModal from '@/Atoms/SlideModal';
import {ScreenName} from '@/navigators/StackConstants';
import Loader from '@/Atoms/CCLoader/CCLoader';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import UploadScoreSheet from '../OTBGames/components/UploadFile/index';
import {Image, Pressable, TouchableOpacity, View} from 'react-native';
import {Chess} from 'chess.js';
import ConditionalRender from '@/Atoms/ConditionalRender';
import MovesList from '@/Atoms/MovesList';
import {TabBar, TabView} from 'react-native-tab-view';
import {renderTabBar} from '@/Components/TabBar';
import SubscriptionModal from '../Subscription/components/Modals';
import Divider from '@/Atoms/Divider/Divider';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ZoomableImage from '@/Atoms/ZoomableImage';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';

function SaveOTBGames({dashboardData}) {
  const navigate = useNavigation();
  const [movesList, setMovesList] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [moveSelected, setmoveSelected] = useState({
    move: '',
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    lastFen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  });
  const handleSavedOTBGame = data => {
    setLimitType(data?.limit_type);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    if (data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
      return;
    }
    if (isReset) {
      otbGamesDetails({game_uuid: gameUuid});
    }
    setIsReset(false);
  };
  const {
    mutate: saveOTBGamePGN,
    data: saveOTBGamePGNData,
    isLoading: isSaving,
    error: saveError,
  } = useSaveOTBGamePGN(handleSavedOTBGame);

  const {isMobileOrTab} = useMediaQuery();
  const [isUploaded, setIsUploaded] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [file, setFile] = useState<File>();
  const [moveInput, setMoveInput] = useState('');
  const [index, setIndex] = useState(0);
  const [boardPosition, setBoardPosition] = useState<string>('start');
  const [clicked, setClicked] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);

  const [routes] = React.useState(
    [
      {key: 'DETAILS', title: 'Game details'},
      {key: 'MOVES', title: 'Moves'},
    ]?.filter(i => i),
  );

  const [imageUrl, setImageUrl] = useState('');
  const [imageBig, setImageBig] = useState<boolean>(false);

  const route = useRoute();
  const gameUuid = route.params?.uuid;
  const [gameDetails, setGameDetails] = useState(null);
  // const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
  //   useState(false);
  const chessboardColorTheme = useChessboardColorTheme();
  const {
    mutate: otbGamesDetails,
    data: otbGamesDetailsData,
    isLoading: otbGamesDetailsLoading,
    error: otbGamesDetailsError,
  } = useGetOTBGameDetails(
    data => {
      setLimitType(data?.limit_type);
      setUsageLimit(data?.usage_limit);
      setPlanName(data?.plan_name);
      if (data?.is_permitted === false) {
        setSubscriptionModalOpen(true);
        return;
      }
      setImageUrl(data?.uploaded_file);
      setGameDetails(data?.game_details);
      const tempMoveList = generateMovesWithFen(data?.pgn_moves);
      // setImageUrl(data?.uploaded_file);

      let newTempMoveList = tempMoveList.map(i => {
        return {
          move: i.san,
          fen: i.fen,
          lastFen: i.lastFen,
        };
      });
      setMovesList(newTempMoveList);
      setmoveSelected(newTempMoveList?.[newTempMoveList?.length - 1]);
    },
    () => {},
  );

  useEffect(() => {
    if (saveOTBGamePGNData?.is_published) {
      navigate.navigate(ScreenName.Games);
    }
  }, [saveOTBGamePGNData]);

  useEffect(() => {
    if (movesList?.length > 0) {
      handleSavePGN(false);
    }
  }, [movesList]);

  const handleSavePGN = (isPublished = false) => {
    // Example PGN compilation logic - adjust according to your actual logic
    // const pgnContent = movesList.map(move => move.san).join(' ');
    if (isPublished) {
      setOpenConfirmation(false);
    }

    const pgnContent = generatePGN(gameDetails, movesList);
    saveOTBGamePGN({gameUuid, pgnContent, isPublished});
  };
  const handleResetMoves = async () => {
    setIsReset(true);
    const pgnContent = generatePGN(gameDetails, []);
    await saveOTBGamePGN({gameUuid, pgnContent, isPublished: false});
    // await otbGamesDetails({game_uuid: gameUuid});
  };

  const playerDetails = ({
    playerType,
    playerName,
    playerRating,
  }: {
    playerType: 'black' | 'white';
    playerName: string;
    playerRating: string;
  }) => {
    const isWhite = playerType === 'white';
    return (
      <Box style={styles.eachPlayerDetails}>
        <Box style={styles.eachPlayerName}>
          <Box style={isWhite ? styles.whiteBox : styles.BrownBox} />
          <CCText fontSize={FontSizes[14]} fontWeight="bold">
            {'  '}
            {playerName}
            {/* {isWhite ? players?.white : players?.black} */}
          </CCText>
        </Box>
        <CCText fontSize={FontSizes[11]} color={CCColors.Grey.Shade100}>
          #{playerRating}
        </CCText>
        <Spacer spacing={10} />
      </Box>
    );
  };

  useEffect(() => {
    if (gameUuid) {
      otbGamesDetails({game_uuid: gameUuid});
    }
  }, [gameUuid]);

  const handlePositionChange = tempPosition => {
    console.log(movesList, 'movesList');
    const indexOfPrev = movesList?.findIndex(
      element => element?.fen === tempPosition?.lastFen,
    );
    const updatedMovesForFEN = [
      ...movesList?.splice(0, indexOfPrev + 1),
      // ...existingMovesForFEN,
      {
        fen: tempPosition?.newFen,
        lastFen: tempPosition?.lastFen,
        move: tempPosition?.san,
        ...tempPosition,
      },
    ];

    // const newTempMoveList=movesList?.indexOf
    setMovesList(prev => updatedMovesForFEN);
    setmoveSelected({
      fen: tempPosition?.newFen,
      lastFen: tempPosition?.lastFen,
      ...tempPosition,
    });
  };

  // useEffect(() => {
  //   //fetching pgn data

  //   otbGamesDetails({game_uuid: gameUuid});
  // }, [isUploaded]);

  // let count = 1;
  // const headers = ['Round #', 'White', 'Black'];
  // const tableData = [];
  // for (let i = 0; i < moves.length; i += 2) {
  //   const whiteMove = moves[i] || '';
  //   const blackMove = moves[i + 1] || '';
  //   tableData.push([
  //     {name: count++, type: 'text'},
  //     {name: whiteMove, type: 'text', isChecked: false},
  //     {name: blackMove, type: 'text', isChecked: false},
  //   ]);
  // }

  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    // let returnJSX = <></>;
    switch (tempRoute.key) {
      case 'DETAILS':
        return <View style={{flex: 1}}>{leftComponent}</View>;
      case 'MOVES':
        return rightMovesComponent;

      default:
        return <View style={{flex: 1}}>{leftComponent}</View>;
    }
    // switch (tempRoute.key) {
  };

  useEffect(() => {
    setMovesList([]);
    if (isUploaded && file) {
      let content;
      try {
        const reader = new FileReader();
        reader.onload = e => {
          console.log('onload', e);
        };
        reader.onerror = e => {
          console.log('reader error', e);
        };

        reader.readAsText(file);

        console.log('content', content);
      } catch (error) {
        console.log('error while reading file', error);
      }
    }
  }, [isUploaded]);
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'game' : 'games'
  } is exhausted. Upgrade now to continue with the analysis.`;
  const leftComponent = (
    <Box flexDirection="row" justifyContent="space-around">
      <Box flex={1}>
        <ConditionalRender
          condition={imageBig && !isMobileOrTab}
          childrenA={
            <Box
              onPressIn={() => setImageBig(false)}
              onPress={() => setImageBig(true)}
              style={{
                borderRadius: 5,
                // width: '45vw',
              }}>
              <Image
                resizeMode="contain"
                resizeMethod="resize"
                source={{uri: imageUrl}}
                alt="uploaded image"
                style={{
                  width: '40vw',
                  height: '84vh',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
            </Box>
          }
          childrenB={
            <Box overflow="hidden">
              {playerDetails({
                playerType:
                  gameDetails?.round_color === 'black' ? 'black' : 'white',

                playerName: gameDetails?.player_name,

                playerRating: gameDetails?.player_fide_rating,
              })}
              {playerDetails({
                playerType:
                  gameDetails?.round_color === 'black' ? 'white' : 'black',
                playerName: gameDetails?.opponent_name,
                playerRating: gameDetails?.opponent_fide_rating,
              })}

              <ConditionalRender
                condition={!imageUrl}
                childrenA={
                  <>
                    <Spacer spacing={20} />
                    <UploadScoreSheet
                      gameUuid={gameUuid}
                      file={file}
                      setFile={setFile}
                      isUploaded={isUploaded}
                      setIsUploaded={setIsUploaded}
                      style={{marginLeft: 'auto', marginRight: 'auto'}}
                      setImageUrl={setImageUrl}
                      imageUrl={imageUrl}
                      postUploadCallback={() => {
                        otbGamesDetails({game_uuid: gameUuid});
                      }}
                    />
                  </>
                }
                childrenB={
                  <Box>
                    {/* <TouchableOpacity onPress={onClose}> */}
                    <Box
                      type="center"
                      style={styles.closeIcon}
                      onPress={() => setImageUrl(null)}>
                      <Ionicons
                        name="close-outline"
                        size={20}
                        color={CCColors.TextColor.Primary}
                      />
                    </Box>
                    {/* </TouchableOpacity> */}
                    <Box
                      overflow="hidden"
                      // onPress={() => setImageBig(true)}
                      gap={5}
                      style={{
                        // width: '90%',
                        borderRadius: 5,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}>
                      <ZoomableImage
                        // resizeMode="contain"
                        // resizeMethod="auto"
                        src={imageUrl}
                        // alt="uploaded image"
                        // style={{
                        //   width: '100%',
                        //   height: WINDOW_HEIGHT / 1.5,
                        //   // marginLeft: 'auto',
                        //   // marginRight: 'auto',
                        // }}
                      />
                      <CCText textAlign="center">
                        Now you can feed the game by looking at this above file
                      </CCText>
                    </Box>
                  </Box>
                }
              />
            </Box>
          }
        />
      </Box>
    </Box>
  );

  const rightMovesComponent = (
    <Box style={styles.right}>
      <MovesList
        gameMoves={movesList}
        activeMove={moveSelected}
        setClicked={setClicked}
        setmoveSelected={setmoveSelected}
        useFeather={false}
        descriptionText="To edit a move, go to the previous one, play the new move, and it will overwrite the rest. "
      />
      {movesList?.length > 0 && (
        <Box>
          <Spacer spacing={20} />
          <Box type="row-center-between">
            <CCButton type="fill" onPress={() => setOpenConfirmation(true)}>
              Publish
            </CCButton>
            <CCButton type="fill" onPress={handleResetMoves}>
              Reset
            </CCButton>
          </Box>
        </Box>
      )}
    </Box>
  );

  if (!isMobileOrTab) {
    return (
      <Box>
        <SlideModal
          type="center"
          modalHeaderTitle={
            <CCText fontSize={FontSizes[14]}>Confirmation</CCText>
          }
          onClose={() => {
            setOpenConfirmation(false);
          }}
          visible={openConfirmation}>
          <Box margin={24}>
            <Spacer spacing={10} />
            <>
              <CCText>
                Published game will be moved to Game Review tab and stop
                appearing in OTB games list.
              </CCText>
              <CCText>
                This game will be saved permanentaly and you won't be able to
                edit it again.
              </CCText>
              <Spacer spacing={10} />
              <Box type="row-flex-end">
                <CCButton
                  type="outline"
                  onPress={() => setOpenConfirmation(false)}>
                  <CCText>Cancel</CCText>
                </CCButton>
                <Spacer spacing={8} horizontal />
                <CCButton type="fill" onPress={() => handleSavePGN(true)}>
                  <CCText>Confirm</CCText>
                </CCButton>
              </Box>
            </>
          </Box>
        </SlideModal>
        <Box style={styles.container}>
          <Box style={styles.left}>
            {/* <ScrollView flex={1} style={{height: '85vh'}}> */}
            {leftComponent}
            {/* </ScrollView> */}
          </Box>
          <Box style={styles.center} overflow="hidden">
            <ChessboardComponent
              position={moveSelected?.fen}
              colorTheme={chessboardColorTheme}
              lastMoveFen={moveSelected?.lastFen}
              handleNewFen={handlePositionChange}
            />
          </Box>
          <Box style={styles.right}>
            <Box style={{height: '85vh'}}>
              <Spacer spacing={10} />

              <MovesList
                gameMoves={movesList}
                activeMove={moveSelected}
                setClicked={setClicked}
                setmoveSelected={setmoveSelected}
                useFeather={false}
                descriptionText="To edit a move, go to the previous one, play the new move, and it will overwrite the rest."
              />

              {movesList?.length > 0 && (
                <Box>
                  <Spacer spacing={20} />
                  <Box type="row-center-between">
                    <Spacer spacing={10} horizontal />
                    <CCButton
                      type="box"
                      style={{backgroundColor: CCColors.White}}
                      // titleStyle={{color:CCColors.}}
                      onPress={handleResetMoves}>
                      Reset
                    </CCButton>
                    <CCButton
                      type="box"
                      titleStyle={{color: CCColors.White}}
                      onPress={() => setOpenConfirmation(true)}>
                      Publish
                    </CCButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* <SubscriptionModal
          visible={isSubscriptionModalVisible}
          onClose={() => setIsSubscriptionModalVisible(false)}
          user={dashboardData?.user}
        /> */}
        <Loader loading={isSaving || otbGamesDetailsLoading} />
      </Box>
    );
  }
  return (
    <>
      <Box style={styles.container}>
        <Loader loading={isSaving || otbGamesDetailsLoading} />
        <SlideModal
          type="center"
          modalHeaderTitle={
            <CCText fontSize={FontSizes[14]}>Confirmation</CCText>
          }
          onClose={() => {
            setOpenConfirmation(false);
          }}
          visible={openConfirmation}>
          <Box margin={24}>
            <Spacer spacing={10} />
            <>
              <CCText>
                Published game will be moved to Game Review tab and stop
                appearing in OTB games list.
              </CCText>
              <CCText>
                This game will be saved permanentaly and you won't be able to
                edit it again.
              </CCText>
              <Spacer spacing={10} />
              <Box type="row-flex-end">
                <CCButton
                  type="outline"
                  onPress={() => setOpenConfirmation(false)}>
                  <CCText>Cancel</CCText>
                </CCButton>
                <Spacer spacing={8} horizontal />
                <CCButton type="fill" onPress={() => handleSavePGN(true)}>
                  <CCText>Confirm</CCText>
                </CCButton>
              </Box>
            </>
          </Box>
        </SlideModal>
        {!isMobileOrTab && (
          <>
            {leftComponent}
            <ConditionalRender
              condition={!isMobileOrTab}
              childrenA={
                <Box style={styles.middle} overflow="hidden">
                  <ChessboardComponent
                    position={moveSelected?.fen}
                    colorTheme={chessboardColorTheme}
                    lastMoveFen={moveSelected?.lastFen}
                    handleNewFen={handlePositionChange}
                  />
                </Box>
              }
            />
            {rightMovesComponent}
          </>
        )}
        {isMobileOrTab && (
          <>
            <ChessboardComponent
              position={moveSelected?.fen}
              lastMoveFen={moveSelected?.lastFen}
              handleNewFen={handlePositionChange}
              colorTheme={chessboardColorTheme}
            />
            <TabView
              lazy
              //   style={styles.contentContainerStyle}
              // @ts-ignore
              navigationState={{index: index, routes}}
              renderScene={renderScene}
              renderTabBar={props => (
                <TabBar
                  {...props}
                  activeColor="black"
                  indicatorStyle={{
                    width: '50%',
                    height: '100%',
                    backgroundColor: CCColors.Primary.lightYellow,
                  }}
                  inactiveColor="grey"
                  style={{backgroundColor: CCColors.White}}
                />
              )}
              onIndexChange={setIndex}
            />
          </>
        )}
      </Box>

      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        user={dashboardData?.user}
        planMessage={planMessage}
        currentPlan={planName}
      />
    </>
  );
}

export default SaveOTBGames;
