import Box from '@/Atoms/Box';
import React, {useEffect, useState} from 'react';
import {data, headers} from './helper';
import {FlatList, Text, View} from 'react-native';
import {styles} from './styles';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import CCButton from '@/Atoms/CCButton';
import {FontSizes, isPhone, vs} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import CCTextInput from '@/Atoms/CCTextInput';
import useGetProfileApi from '@/Hooks/ProfileApi/profileApi';
import {
  faChessPawn,
  faChessKnight,
  faChessQueen,
  faChessBishop,
  faChessRook,
  faChessKing,
  faChess,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useUpdateProfileApi from '@/Hooks/ProfileApi/profileUpdateApi';
import useGAPageView from '@/navigators/useGAPageView';
import {ScreenName} from '@/navigators/StackConstants';
import CCSelect from '@/Atoms/CCSelect';
import {
  setLocalStorage,
  getLocalStorage,
  getUserDetails,
} from '@/Utils/CommonUtils';
import localforage from 'localforage';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Header from '@/Atoms/Header';
import {useLoginState} from '@/Hooks/LoginApi/loginApi';
import {lastValueFrom} from 'rxjs';

function Profile({setUserData}) {
  useGAPageView(ScreenName.Settings);

  const data = [
    {
      key: 'A',
      icon: faChessPawn,
      name: 'Persistent Pawn',
      tagline:
        'There are so few like you, determined, unrelenting, positional and winner!',
    },
    {
      key: 'B',
      icon: faChessKnight,
      name: 'Killer Knight',
      tagline:
        'Tricky, deceptive, positional and attacking, you are an artist!',
    },
    {
      key: 'C',
      icon: faChessBishop,
      name: 'Blazing Bishop',
      tagline:
        'You fancy blazing through the 64 squares, leaving a trail of destruction!',
    },
    {
      key: 'D',
      icon: faChessRook,
      name: 'Rumbling Rook',
      tagline:
        'You are a heavy weight champion, crushing your enemies beneath your feet.',
    },
    {
      key: 'E',
      icon: faChessQueen,
      name: 'Qrushing Queen',
      tagline:
        'Your aggression has no limits, you crush all that come in your path.',
    },
    {
      key: 'F',
      icon: faChessKing,
      name: 'Kingly King',
      tagline:
        'You are majestic, silent, strategic leader, marshalling your forces to victory!',
    },
  ];

  const [firstName, setFirstName] = useState('');
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fideid, setFideid] = useState('');
  const [lichessUsername, setLichessUsername] = useState('');
  const [cdcUsername, setCdcUsername] = useState('');
  const [avatar, setAvatar] = useState('');
  const [avatarDP, setAvatarDP] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [color, setColor] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [isFideDisabled, setIsFideDisabled] = useState(false);
  const [avatarInfo, setAvatarInfo] = useState<{
    avatarIcon: any;
    avatarName: string;
    avatarTagline: string;
  }>({
    avatarIcon: faChessBishop,
    avatarName: 'Blazing Bishop',
    avatarTagline:
      'You fancy blazing through the 64 squares, leaving a trail of destruction!',
  });

  const formatData = (data, numColumns) => {
    const numberOfFullRows = Math.floor(data.length / numColumns);

    let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
    while (
      numberOfElementsLastRow !== numColumns &&
      numberOfElementsLastRow !== 0
    ) {
      data.push({key: `blank-${numberOfElementsLastRow}`, empty: true});
      numberOfElementsLastRow++;
    }

    return data;
  };

  const {isMobileOrTab} = useMediaQuery();

  const numColumns = 3;

  const renderItem = ({item, index}) => {
    if (item.empty === true) {
      return <View style={[styles.item, styles.itemInvisible]} />;
    }
    return (
      <Box
        style={styles.item}
        onPress={e => {
          setAvatarInfo({
            avatarIcon: item.icon,
            avatarName: item.name,
            avatarTagline: item.tagline,
          });
        }}>
        <FontAwesomeIcon icon={item.icon} size="x" color="black" />
        <Spacer spacing={12} />
        <CCText style={styles.itemText}>{item.name}</CCText>
      </Box>
    );
  };

  const handleProfileGetSuccess = (responseData: any) => {
    console.log('Profile Data = ', responseData);
    setSuccess(false);
    setErrorMsg(null);
    const user = responseData.data.user[0].fields;
    const avatarData = responseData.data.avatar[0].fields;
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setUserName(user.username);
    setPhone(user.mobile_number);
    setFideid(user.fide_id || '');
    setLichessUsername(user.lic_name);
    setCdcUsername(user.cdc_name);
    setAvatar(avatarData.avatarDP);
    setAvatarDP(avatarData.avatar);
    if (user.lic_name !== null) {
      setIsDisabled(true);
    }
    if (user.cdc_name !== '' || null) {
      setIsSaved(true);
    }
    if (user.email !== '') {
      setIsEmailDisabled(true);
    }
    if (user.fide_id !== 0) {
      setIsFideDisabled(true);
    }
  };

  const getUserInfo = async () => {
    const userDetails = await getLocalStorage('user_details');

    if (userDetails) {
      console.log('userDetails', userDetails);

      return userDetails;
    }
  };
  const handleProfileUpdateSuccess = async (responseData: any) => {
    setSuccess(true);
    const getUserDetails = await getUserInfo();
    let parseUserDetails = JSON.parse(getUserDetails);
    parseUserDetails.last_name = responseData.data.user[0].fields.last_name;
    parseUserDetails.first_name = responseData.data.user[0].fields.first_name;
    parseUserDetails.username = responseData.data.user[0].fields.username;
    parseUserDetails.fide_id = responseData.data.user[0].fields.fide_id;
    parseUserDetails.lic_name = responseData.data.user[0].fields.lic_name;
    parseUserDetails.cdc_name = responseData.data.user[0].fields.cdc_name;
    parseUserDetails.email = responseData.data.user[0].fields.email;
    const updatedUserDetails = JSON.stringify(parseUserDetails);
    console.log('updated user details', updatedUserDetails);
    setUserData(parseUserDetails);

    const updateLocalStorage = async () => {
      try {
        await setLocalStorage(
          'user_details',
          updatedUserDetails,
          false,
          () => {},
        );
      } catch (error) {
        console.error('Failed to set local storage:', error);
      }
    };
    updateLocalStorage();

    console.log('Profile Update Respnse = ', responseData);
  };

  const handleProfileUpdateFailure = (responseData: any) => {
    console.log('Profile Update Error Respnse = ', responseData);
    setErrorMsg(responseData.error);
  };

  const handleOnError = (responseData: any) => {};

  const {
    mutate: getProfile,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useGetProfileApi(handleProfileGetSuccess, handleOnError);

  const {
    mutate: saveProfile,
    data: successResults,
    error: errorResults,
  } = useUpdateProfileApi(
    handleProfileUpdateSuccess,
    handleProfileUpdateFailure,
  );

  useEffect(() => {
    console.log('Getting Goals .. ');
    setSuccess(false);
    getProfile();
  }, []);

  const getValueOrEmptyString = (value: string) => {
    if (value && value.trim().length > 0) {
      return value;
    }

    return '';
  };

  const updateProfile = () => {
    setErrorMsg(null);
    setSuccess(false);
    const payload = {
      first_name: getValueOrEmptyString(firstName),
      last_name: getValueOrEmptyString(lastName),
      email: getValueOrEmptyString(email),
      mobile_number: getValueOrEmptyString(phone),
      fide_id: getValueOrEmptyString('' + fideid),
      lic_name: getValueOrEmptyString(lichessUsername),
      cdc_name: getValueOrEmptyString(cdcUsername),
      username: getValueOrEmptyString(userName),
    };

    if (lastName.trim() === '') {
      setErrorMsg('Please provide a valid Last Name');
      setSuccess(false);
      return;
    }
    saveProfile(payload);
  };

  useEffect(() => {
    const storeColor = async () => {
      console.log('color', color);
      await setLocalStorage('color', color);
    };
    storeColor();
  }, [color]);

  useEffect(() => {
    const fetchColor = async () => {
      const tempColor = await getLocalStorage('color');
      // debugger;
      if (tempColor) {
        setColor(tempColor);
      } else {
        setColor('default-yellow-white');
      }
    };
    fetchColor();
  }, []);

  return (
    <Box flex={1}>
      <Spacer spacing={isPhone ? vs(12) : vs(36)} />
      <CCText type="primary" fontSize={FontSizes[24]} style={styles.header}>
        Your CircleChess Profile
      </CCText>
      <Box style={{marginLeft: isPhone ? vs(12) : vs(120)}}>
        <Spacer spacing={isPhone ? vs(24) : vs(36)} />
        <CCText
          type="primary"
          fontSize={FontSizes[14]}
          style={styles.sectionHeader}>
          Your Personal Profile
        </CCText>
        <Box flexDirection={!isPhone ? 'row' : 'column'}>
          <Box flex={0.5} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setFirstName(text)}
              label="First Name"
              value={firstName}
            />
          </Box>
          <Box flex={0.5} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setLastName(text)}
              label="Last Name"
              value={lastName}
            />
          </Box>

          <Box
            style={{alignSelf: isMobileOrTab ? 'flex-start' : 'flex-end'}}
            flex={1}
            flexDirection="row"
            alignItems="center"
            gap={2}>
            <CCText style={{marginLeft: '1%'}}>Choose ChessBoard Color</CCText>
            <CCSelect
              label=""
              selectedValue={color}
              onValueChange={setColor}
              items={[
                {label: 'default-yellow-white', value: 'default-yellow-white'},
                {label: 'green-white', value: 'green-white'},
                {label: 'blue-white', value: 'blue-white'},
              ]}
              style={{
                backgroundColor: 'white',
                // width: '100%',
                height: 35,
                marginLeft: '1%',
                borderColor: CCColors.BorderBottom,
              }}
            />
          </Box>
        </Box>
        <Box flexDirection={!isPhone ? 'row' : 'column'}>
          <Box flex={0.5} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setUserName(text)}
              label="Username"
              value={userName}
            />
          </Box>
          <Box flex={0.5} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setEmail(text)}
              label="Email"
              value={email}
              disabled={isEmailDisabled}
            />
          </Box>
          <Box flex={0.5} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setPhone(text)}
              label="Phone"
              value={phone}
              inputType="phone"
              disabled={phone}
            />
          </Box>
        </Box>
        <Spacer spacing={vs(24)} />
        <CCText
          type="primary"
          fontSize={FontSizes[14]}
          style={styles.sectionHeader}>
          Your Chess Profile
        </CCText>
        <Box flexDirection={!isPhone ? 'row' : 'column'}>
          <Box flex={0.3} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setFideid(text)}
              label="Your FIDE ID"
              value={fideid}
              disabled={isFideDisabled}
            />
          </Box>
          <Box flex={0.3} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setLichessUsername(text)}
              label="Lichess Username"
              value={lichessUsername}
              disabled={isDisabled}
            />
          </Box>
          <Box flex={0.3} style={styles.formElements}>
            <CCTextInput
              onChangeText={text => setCdcUsername(text)}
              label="Chess.com Username"
              value={cdcUsername}
              disabled={isSaved}
            />
          </Box>
        </Box>
        <Spacer spacing={isPhone ? vs(24) : vs(48)} />

        {errorMsg && (
          <Box justifyContent="center">
            <CCText
              fontSize={FontSizes[14]}
              style={{color: 'red', marginLeft: vs(18)}}>
              {errorMsg}
            </CCText>
            <Spacer spacing={vs(48)} />
          </Box>
        )}
        {success && (
          <Box justifyContent="center">
            <CCText
              fontSize={FontSizes[14]}
              style={{color: 'green', marginLeft: vs(18)}}>
              Profile successfully updated
            </CCText>
            <Spacer spacing={vs(48)} />
          </Box>
        )}

        <Box
          flexDirection={!isPhone ? 'row' : 'column'}
          justifyContent="center">
          <CCButton
            style={[styles.buttonStyles, {margin: 'auto'}]}
            onPress={updateProfile}>
            <CCText fontSize={FontSizes[16]}>Save</CCText>
          </CCButton>
        </Box>
        {/* <Spacer spacing={vs(24)} />
      <CCText type='primary' style={{ textAlign: 'left', fontWeight: 'bold', paddingLeft: vs(12) }}>Your Avatar</CCText>
      <Box flexDirection="row"
        justifyContent="center"
        alignItems='center'
      >
        <Box style={{ flex: 0.1 }}>
        </Box>
        <Box style={{ flex: 0.4 }}>
          <FlatList
            data={formatData(data, 3)}
            style={styles.container}
            renderItem={renderItem}
            numColumns={3} />
        </Box>
        <Spacer spacing={vs(36)} horizontal={true} />
        <Box
          flexDirection='column'
          justifyContent="center"
          alignItems='center'
          style={{ paddingTop: vs(12), padding: vs(12), width: vs(50), flex: 0.2 }}
        >
          <FontAwesomeIcon
            icon={avatarInfo.avatarIcon}
            size={!isPhone ? '4x' : '3x'}
            color='#4D3F37'
            border={true}
            style={{ borderWidth: 1, borderColor: '#4D3F37' }} />
          <Spacer spacing={vs(12)} />
          <CCText style={{ textAlign: 'center', fontSize: 14 }}>{avatar}</CCText>
        </Box>
      </Box> */}
      </Box>
    </Box>
  );
}

export default Profile;
