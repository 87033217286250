import React, {useEffect} from 'react';
import {styles} from './styles';

import {Text, View} from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';

import {
  CommonActions,
  DrawerActions,
  useLinkBuilder,
} from '@react-navigation/native';

import {DrawerContentScrollView} from '@react-navigation/drawer';
import {LinearGradient} from 'expo-linear-gradient';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Pressable} from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  interpolateColor,
  withTiming,
  withRepeat,
  withSpring,
} from 'react-native-reanimated';
import CCText from '../CCText';
import CCColors from '@/Utils/CCColors';
import {CustomInfoBox} from '../InfoIcon';
import Tooltip from '../Tooltip';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
function MenuItem({
  icon,
  label,
  focused,
  onPress,
  isMobile,
  disabled,
  tooltipText,
}) {
  var iconName = icon ? icon : 'help';
  iconName = focused ? iconName : `${iconName}-outline`;

  // This animation code is super bug prone!!!
  const bgAnim = useSharedValue(0);
  const bgAnimStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        bgAnim.value,
        [0, 1],
        ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.1)'],
      ),
    };
  });

  function toggleBgAnim(value) {
    if (!focused) {
      bgAnim.value = withTiming(value, {duration: 200});
    }
  }

  if (focused) {
    bgAnim.value = 1;
  } else {
    bgAnim.value = 0;
  }

  function onPressWrapper() {
    focused = true; // this is important
    onPress();
  }

  const iconNode = (
    <Animated.View
      style={[
        styles.menuIcon,
        !isMobile && bgAnimStyle,
        isMobile && styles.mobileMenuIcon,
      ]}>
      {disabled ? (
        <Tooltip
          text={tooltipText}
          children={toggle => {
            const handlePress = () => {
              toggle(true); // Show the tooltip
              setTimeout(() => {
                toggle(false); // Hide the tooltip after 3 seconds
              }, 3000);
            };
            return (
              <Pressable
                onPress={handlePress}
                onHoverIn={toggle}
                onHoverOut={toggle}>
                <View>{<Icon name={iconName} size={30} color="#fff" />}</View>
              </Pressable>
            );
          }}
        />
      ) : (
        <Icon name={iconName} size={30} color="#fff" />
      )}
    </Animated.View>
  );

  return (
    <Pressable
      onPress={onPressWrapper}
      disabled={disabled}
      onHoverIn={() => toggleBgAnim(1)}
      onHoverOut={() => toggleBgAnim(0)}
      children={state => {
        return (
          <View
            style={[
              styles.menuItem,
              isMobile && styles.mobileMenuItem,
              isMobile && focused && styles.mobileMenuItemFocused,
            ]}>
            {iconNode}
            <CCText
              style={[
                [
                  styles.menuItemText,
                  label === 'Visualization Trainer' && {fontSize: 10},
                ],
                [
                  isMobile && styles.mobileMenuItemText,
                  isMobile &&
                    label === 'Visualization Trainer' && {fontSize: 19},
                ],
              ]}
              numberOfLines={2}
              allowFontScaling={true}>
              {label}
            </CCText>
          </View>
        );
      }}></Pressable>
  );
}

function MenuItemList({
  state,
  navigation,
  descriptors,
  isMobile,
  disabled,
  userIsStaff,
}) {
  return state.routes.map((route, i) => {
    const focused = i === state.index;

    const onPress = () => {
      const event = navigation.emit({
        type: 'drawerItemPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!event.defaultPrevented) {
        navigation.dispatch({
          ...(focused
            ? DrawerActions.closeDrawer()
            : CommonActions.reset({
                index: 0,
                routes: [{name: route.name}],
              })),
          target: state.key,
        });
      }
    };

    // console.log('menu', route)

    const opts = descriptors[route.key].options;
    const label = opts.label ? opts.label : route.name;
    const tooltipText = `${label} tab is disabled till analysis of your games is completed`;
    // let userDetails = getUserDetails();
    // userDetails = JSON.parse(userDetails);
    // const is_staff = userDetails?.is_staff;
    if (
      !opts.hide ||
      (opts?.hide !== undefined &&
        opts?.hide === true &&
        userIsStaff &&
        opts?.icon)
    ) {
      // if (opts?.hide !== undefined && opts?.hide === true && userIsStaff) {
      //   debugger;
      // }
      return (
        <MenuItem
          key={route.key}
          label={label}
          icon={opts.icon}
          focused={focused}
          onPress={onPress}
          isMobile={isMobile}
          disabled={opts.disabled}
          tooltipText={tooltipText}
        />
      );
    }
  }) as React.ReactNode as React.ReactElement;
}

export default function MainMenu(props) {
  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const bounceAnim = useSharedValue(0);

  // Set up the bounce animation
  useEffect(() => {
    bounceAnim.value = withRepeat(
      withSpring(10, {
        damping: 2,
        stiffness: 200,
      }),
      -1, // Repeat indefinitely
      true, // Reverse on every iteration
    );
  }, []);

  const bounceStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: bounceAnim.value,
      },
    ],
  }));

  const handleScroll = event => {
    const yOffset = event.nativeEvent.contentOffset.y;
    const viewportHeight = event.nativeEvent.layoutMeasurement.height;
    const totalContentHeight = event.nativeEvent.contentSize.height;

    const atBottom = yOffset + viewportHeight >= totalContentHeight;

    setIsScrolledToBottom(atBottom);
  };
  return (
    <DrawerContentScrollView
      {...props}
      showsVerticalScrollIndicator={false}
      onScroll={handleScroll}
      scrollEventThrottle={16}>
      <MenuItemList {...props} />
      {!isScrolledToBottom && (
        <LinearGradient
          colors={['transparent', 'rgba(0,0,0,1)']}
          style={[styles.fadeBottom, isMobileOrTab && styles.fadeBottomMobile]}>
          <Animated.View style={bounceStyle}>
            <Entypo
              name="chevron-down"
              size={30}
              color="white"
              style={{alignSelf: 'center', marginVertical: 5}}
            />
          </Animated.View>
        </LinearGradient>
      )}
    </DrawerContentScrollView>
  );
}
