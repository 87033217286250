import { createContext, useEffect, useState } from "react";
// const cc_ws_client = require('/Users/nitu/Circlechess/cc-client-sdk/lib/index.js');
const cc_ws_client = require('/var/www/game-service/cc-client-sdk/lib/index.js');
import Environment from '../../environment';

// const socket = cc_ws_client(Environment.GAME_SERVICE_URL, {
//     transports: ['websocket'],
//     forceNew: true,
// });

export const SocketContext = createContext(null);

interface ISocketProvider {
  children: React.ReactNode;
  username: string;
  accessToken: string;
}

export const SocketProvider = (props: ISocketProvider) => {
  const [gameClient, setGameClient] = useState(null);
  const {
    children, username, accessToken
  } = props;


// Todo : change the hardcoded deviceId and SessionId
  useEffect(() => {
    if (username && accessToken) {
      const socket = cc_ws_client(Environment.GAME_SERVICE_URL, {
        transports: ['websocket'],
        forceNew: true,
        query: {
          username: username,
          deviceId:'92bd7016-002a-4983-ad2f-fb41b9ea45b2',
          sessionId:'6c807ca8-cfc8-4866-9ae8-f95bf66fcf2d',
          Authorization: accessToken
        }
      });
      setGameClient(socket);
    }
  }, [username, accessToken])
  
  return (
    <SocketContext.Provider value={gameClient}>{children}</SocketContext.Provider>
  )
};
