import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import draw_control from '@/Assets/Images/png/draw.png';
import resign_control from '@/Assets/Images/png/resign.png';
import abort_control from '@/Assets/Images/png/abort.png';
import ShimmerLoader from '../ShimmerLoader';
import {CustomInfoBox} from '@/Atoms/InfoIcon';
import styles from './styles';

interface BottomControlsProps {
  showDrawRequest?: boolean;
  drawRequestSent?: boolean;
  handleDrawResponse?: () => void;
  drawRequest?: () => void;
  resign?: () => void;
  abort?: () => void;
  showAbort?: boolean;
  playing: boolean;
  setShowDrawReject?: (show: boolean) => void;
  showDrawReject?: boolean;
}

const BottomControlsComponent: React.FC<BottomControlsProps> = ({
  showDrawRequest = false,
  drawRequestSent = false,
  handleDrawResponse = () => {},
  drawRequest = () => {},
  resign = () => {},
  abort = () => {},
  showAbort = true,
  playing,
  setShowDrawReject = () => {},
  showDrawReject = false,
}) => {
  const [showConfirmResign, setShowConfirmResign] = useState(false);
  const [showConfirmAbort, setShowConfirmAbort] = useState(false);

  const handleConfirmResign = () => {
    setShowConfirmResign(false);
    resign();
  };

  const handleConfirmAbort = () => {
    setShowConfirmAbort(false);
    abort();
  };

  return (
    <Box flexDirection="row" style={styles.container}>
      {/* Case 1: Draw request received */}
      {showDrawRequest && (
        // <Box style={styles.leftPosition}>
        <CustomInfoBox
          text="Accept draw"
          customView={
            <Box style={styles.infoBox}>
              <Box style={styles.centeredBox} onPress={handleDrawResponse}>
                <CCText style={styles.centeredText}>
                  Draw offered, click to accept!
                </CCText>
              </Box>
            </Box>
          }
        />
        // </Box>
      )}

      {/* Case 2: Draw request sent */}
      {drawRequestSent && (
        // <Box style={styles.leftPosition}>
        <ShimmerLoader isLoading={true} >
          <Box style={styles.infoBox}>
            <Box style={styles.centeredBox}>
              <CCText style={styles.centeredText}>
                Draw offered, waiting for response!
              </CCText>
            </Box>
          </Box>
        </ShimmerLoader>
        // </Box>
      )}

      {/* Case 3: Draw offer rejected */}
      {showDrawReject && (
        // <Box style={styles.leftPosition}>
        <ShimmerLoader isLoading={true} >
          <Box style={styles.infoBox}>
            <Box style={styles.centeredBox}>
              <CCText style={styles.centeredText}>
                Your draw offer was rejected!
              </CCText>
            </Box>
          </Box>
        </ShimmerLoader>
        // </Box>
      )}

      {/* Case 4: Request draw */}
      {!showDrawRequest && !drawRequestSent && !showAbort && playing && (
        <CustomInfoBox
          text="Request draw"
          customView={
            <Box style={styles.actionBox} onPress={drawRequest}>
              <Image source={draw_control} style={styles.icon} />
            </Box>
          }
        />
      )}

      {/* Case 5: Resign */}
      {!showAbort && playing && (
        <CustomInfoBox
          text="Resign"
          customView={
            <Box
              style={styles.resignBox}
              onPress={() => setShowConfirmResign(true)}>
              <Image source={resign_control} style={styles.icon} />
            </Box>
          }
        />
      )}

      {/* Confirm Resign Modal */}
      {showConfirmResign && (
        <View style={styles.modal}>
          <Text style={styles.modalText}>Are you sure you want to resign?</Text>
          <View style={styles.modalButtons}>
            <TouchableOpacity
              style={styles.modalButton}
              onPress={() => setShowConfirmResign(false)}>
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.modalButton, styles.confirmButton]}
              onPress={handleConfirmResign}>
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      {/* Confirm Abort Modal */}
      {showConfirmAbort && (
        <View style={styles.modal}>
          <Text style={styles.modalText}>Are you sure you want to abort?</Text>
          <View style={styles.modalButtons}>
            <TouchableOpacity
              style={styles.modalButton}
              onPress={() => setShowConfirmAbort(false)}>
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.modalButton, styles.confirmButton]}
              onPress={handleConfirmAbort}>
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      {/* Abort game */}
      {showAbort && (
        <CustomInfoBox
          text="Abort game"
          customView={
            <Box
              style={styles.abortBox}
              onPress={() => setShowConfirmAbort(true)}>
              <Image source={abort_control} style={styles.icon} />
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default BottomControlsComponent;
