import React from 'react';
import {Text, Linking, ScrollView} from 'react-native';
import {Table, Row} from 'react-native-table-component';
import {Picker} from '@react-native-picker/picker';
import CCText from '../CCText';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

interface CustomCellProps {
  item: {
    name: string;
    type: 'text' | 'link' | 'dropdown' | 'highlighted_text';
    url?: string;
    options?: string[];
  };
}

const CustomCell: React.FC<CustomCellProps> = ({item}) => {
  switch (item.type) {
    case 'text':
      return <CCText>{item.name}</CCText>;
    case 'dropdown':
      return (
        <Picker selectedValue={item.name}>
          {item.options?.map((option, index) => (
            <Picker.Item key={index} label={option} value={option} />
          ))}
        </Picker>
      );
    case 'link':
      return (
        <Text
          style={{color: 'blue'}}
          onPress={() => item.url && Linking.openURL(item.url)}>
          {item.name}
        </Text>
      );
    case 'highlighted_text':
      return (
        <Box
          style={{backgroundColor: CCColors.GamesList.Background, padding: 0}}>
          <CCText>{item.name}</CCText>
        </Box>
      );
    default:
      return <Text>{item.name}</Text>;
  }
};

interface CCPackageTableProps {
  headers: string[];
  tableData: Array<
    Array<{
      name: string;
      type: 'text' | 'link' | 'dropdown';
      url?: string;
      options?: string[];
    }>
  >;
  flexArr?: number[];
  headerTextColor?: string;
  borderBottomColor?: string;
  borderBottomWidth?: boolean;
  isMargin?: boolean;
  isPadding?: boolean;
}

const CCPackageTable: React.FC<CCPackageTableProps> = ({
  headers,
  tableData,
  headerTextColor = CCColors.TextColor.Dark,
  borderBottomColor = CCColors?.Primary,
  borderBottomWidth = true,
  isMargin = true,
  flexArr = [],
  isPadding = false,
  ...rest
}) => {
  const {isMobileOrTab} = useMediaQuery();
  const formattedData = tableData.map(row =>
    row.map(item => <CustomCell key={item.name} item={item} />),
  );

  console.log('bottom color', borderBottomColor, headerTextColor);

  return (
    <ScrollView
      showsVerticalScrollIndicator={!isMobileOrTab ? false : undefined}
      style={{width: '100%'}}>
      <Box style={styles.wrapper} {...rest}>
        {isMobileOrTab ? (
          // Render each row as a block if on a small screen
          formattedData.map((rowData, index) => (
            <Box key={index} style={styles.blockRow}>
              {rowData.map((cell, cellIndex) => (
                <Box key={cellIndex} style={styles.cellBlock}>
                  {cell}
                </Box>
              ))}
            </Box>
          ))
        ) : (
          // Render the normal table if not on a small screen
          <>
            <Table borderStyle={{borderBottomWidth: 1, backgroundColor: 'red'}}>
              <Row
                data={headers}
                style={[
                  styles.head,
                  {
                    borderBottomWidth: 0,
                    backgroundColor: 'white',
                  },
                ]}
                flexArr={flexArr}
                textStyle={[styles.text, {color: headerTextColor}]}
              />
            </Table>
            {formattedData?.length > 0 ? (
              <ScrollView
                style={styles.dataWrapper}
                showsVerticalScrollIndicator={false}>
                <Table
                  borderStyle={{borderBottomWidth: 1, backgroundColor: 'red'}}>
                  {formattedData.map((rowData, index) => (
                    <Row
                      style={[
                        styles.row,
                        index % 2 === 0 ? {backgroundColor: 'white'} : null,
                        {
                          borderBottomColor: 'transparent',
                          // margin: isMargin ? 5 : 0,
                          // padding: isMargin ? 0 : 10,
                          padding: isMargin ? 0 : isPadding ? 10 : 0,
                        },
                      ]}
                      flexArr={flexArr}
                      key={index}
                      data={rowData}
                      textStyle={styles.text}
                    />
                  ))}
                </Table>
              </ScrollView>
            ) : (
              <CCText
                color={CCColors.Grey.Grey}
                textAlign="center"
                style={{marginVertical: 30}}>
                No data available
              </CCText>
            )}
          </>
        )}
      </Box>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    // flexDirection: 'row',
    borderWidth: 1,
    margin: 10,
    width: '95%',
    // borderColor: CCColors.TextColor.Grey,
    borderColor: 'rgba(0,0,0,0.1)',
    margin: 'auto',
    borderRadius: 8,
  },
  tableHeader: {
    borderWidth: 0,
    borderColor: 'transparent',
    // width: '95%',
  },
  dataWrapper: {
    // marginTop: -1,
    borderWidth: 0,
  },
  head: {
    height: 40,
    backgroundColor: CCColors.Primary.Light,
    // borderBottomWidth: 1,
    borderColor: CCColors.TextColor.Grey,
  },
  text: {
    margin: 6,
    // color: CCColors.TextColor.Dark,
    fontWeight: '500',
  },
  row: {
    borderBottomWidth: 1,
    // borderBottomColor: CCColors?.Primary,
    // borderWidth: 0,
    minWidth: 200,
    // margin: 5,
    // padding: 10,
  },
  blockRow: {
    flexDirection: 'column',
    margin: 5,
    borderWidth: 1,
    borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
  },
  cellBlock: {
    borderBottomWidth: 1,
    borderBottomColor: CCColors.TextColor.Grey,
    padding: 10,
  },
  // Remove the last borderBottom for the last cellBlock
  lastCellBlock: {
    borderBottomWidth: 0,
  },
});

export default CCPackageTable;
