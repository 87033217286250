import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import {View, TextInput, BackHandler, Alert, Switch} from 'react-native';
import usePuzzleFeedback from '@/Hooks/FeedbackApi/useFeedback';
import Spacer from '@/Atoms/Spacer';
import {
  vs,
  isPhone,
  FontSizes,
  hs,
  isPortrait,
  SCREEN_WIDTH,
} from '../../Utils/Dimensions';
import {styles, filterStyles, infoStyles, rightPaneStyles} from './styles';
import ChessboardComponent from 'chessboard-package';
import {joinGameHandler} from './gamePlaHandlers';
import {Chess} from 'chess.js';
// import DrawRequestModal from './DrawRequestModal';
import {Modal, Text, Button, ScrollView, TouchableOpacity} from 'react-native';
import useGetProfileApi from '@/Hooks/ProfileApi/profileApi';
import Environment from '../../../environment';

import TimeControl from './components/TimeControl';
import GameSearchComponent from './components/GameSearch';

import useSaveCircleChessGame from '@/Hooks/SaveCircleChessGame/useSaveCircleChessGame';
import useCheckCircleChessGamesAnalyzedRule from '@/Hooks/SaveCircleChessGame/useCheckCircleChessGamesAnalyzedRule';
import useGetGameWithTag from '@/Hooks/GameLibraryApi/useGetGameWithTagApi';
import ClockComponent from './components/ClockComponent';
import PlayerComponent from './components/PlayerComponent';
import NotationComponent from './components/NotationComponent';
import ChallengeComponent from '../../Components/ChallengeComponent';
import ResultComponent from './components/ResultComponent';
import rematch from '@/Assets/Images/icons/Rematch.png';

import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import CCColors from '@/Utils/CCColors';
import {Pressable} from 'react-native';
import ProgressBar from '@/Atoms/ProgressBar';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  interpolateColor,
  interpolate,
  withTiming,
} from 'react-native-reanimated';
import useAnimation from '@/Hooks/useAnimation';
import SearchProgressComponent from './components/SearchProgressComponent';
import ChallengeProgressComponent from './components/ChallengeProgressComponent';
import draw_control from '@/Assets/Images/png/draw.png';
import resign_control from '@/Assets/Images/png/resign.png';
import {ScreenName} from '@/navigators/StackConstants';
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from '@react-navigation/native';
import ComingSoon from '../ComingSoon';
import ShimmerLoader from './components/ShimmerLoader';
import BottomControlsComponent from './components/BottomControlsComponent';
import {getLocalStorage, setLocalStorage} from '@/Utils/CommonUtils';
import ChallengeRejected from './components/ChallengeRejected';
import GamePlayClient from '@/Api/GameClient';
import GameRules from './components/GameRulesComponent';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import useAssistedGameMoveAnalysisApi from '@/Hooks/AssistedGameMoveAnalysisApi/useAssistedGameMoveAnalysisApi';
import IdleStateGameBanner from './components/IdleStateGameBanner';
import {
  useNonPersistedUserPlanStore,
  useUserPlanStore,
} from '@/Store/userPlanStore';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import NotificationBox from './components/NotificationBox';
import {useSocket} from '@/Hooks/UseSocket';
import useGamePlayState from '@/Store/GamePlayStore';
import {HintForIncorrectAttempt} from '../Puzzles/next';
import useAssistedPuzzleFeedback from '@/Hooks/FeedbackApi/assistedFeedback';
import ConnectionModal from './components/ConnectionModal';
// const schema = require('/Users/whynew/Circlechess/cc-client-sdk/lib/schema.js');

const {v4: uuidv4} = require('uuid');

// const cc_ws_client = require('/var/www/game-service/cc-client-sdk/lib/index.js');
 const schema = require('/var/www/game-service/cc-client-sdk/lib/schema.js');

// const cc_ws_client = require('/home/abhijeet/cc-client-sdk/lib/index');
//const schema = require('/Users/nitu/Circlechess/cc-client-sdk/lib/schema.js');

function classifyTimeControl(duration, increment) {
  // Calculate the total time in minutes
  const totalTime = (duration + increment) / 60000;

  // Classify based on total time
  if (totalTime < 3) {
    return 'Bullet';
  } else if (totalTime < 10) {
    return 'Blitz';
  } else if (totalTime < 30) {
    return 'Rapid';
  } else {
    return 'Classical';
  }
}

function useIsFirstRender() {
  const isFirst = useRef(true);

  useEffect(() => {
    isFirst.current = false;
  }, []);

  return isFirst.current;
}

function PlayChess() {
  const isFirstRender = useIsFirstRender();
  const [playing, setPlaying] = useState(false);
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(true);
  const [cntr, setCntr] = useState(0);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [username, setUsername] = useState('');
  const [game_uuid, setGameUuid] = useState('');
  const navigation = useNavigation();
  const [isStaff, setIsStaff] = useState(true);
  const [isStaffNew, setIsStaffNew] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [showChange, setShowChange] = useState(false);
  const [goodFeedback, setGoodFeedback] = useState(0);
  const [badFeedback, setBadFeedback] = useState(0);
  const [displayName, setDisplayName] = useState('');
  const [opponentDisplayName, setOpponentDisplayName] = useState('');
  const chessboardColorTheme = useChessboardColorTheme();
  const route = useRoute();
  const {setSubscriptionModalOpen} = useNonPersistedUserPlanStore();
  const userPlanDetails = useUserPlanStore();
  const [activePlan, setActivePlan] = useState(
    userPlanDetails.activePlan || 'novice',
  );
  const [assistedGameplay, setAssistedGameplay] = useState(false);
  const [gameDetails, setGameDetails] = useState('');
  const [gamePlayMode, setGamePlayMode] = useState('Play');
  const [hintMessage, setHintMessage] = useState('');
  // retry move on packet drop states
  const [lastMove, setLastMove] = useState('');
  const [lastMoveSent, setLastMoveSent] = useState(false);
  const lastMovetimeoutRef = useRef(null);
  const [userId, setUserId] = useState('');
  const [uuid, setUuid] = useState(0);
  const [enableLearn, setEnableLearn] = useState(false);

  const gameplayStore = useGamePlayState();

  const chessboardRef = useRef(null);

  const socket = useSocket();

  useEffect(() => {
    if (chessboardRef.current) {
    } else {
      // console.log('Ref is not set yet.');
    }
  }, [chessboardRef]); // This effect runs whenever chessboardRef changes

  const challengePacket = route.params?.challengePacket;
  const challengeTimeControl = route.params?.timeCOntrol;

  const handleClearPremoves = () => {
    // console.log('clearing premoves 1');
    if (chessboardRef.current) {
      // console.log('clearing premoves 2');
      chessboardRef.current.clearPremoves();
    }
  };

  const handleProfileGetSuccess = responseData => {
    // console.log('Profile Data = ', responseData);
    setSuccess(false);
    setErrorMsg(null);
    const user_id = responseData.data.user[0].pk;
    const avatarData = responseData.data.avatar[0].fields;
    const display_name = responseData.data.user[0].fields.username;
    setUsername(user_id.toString()); // logging in to the socket using user_id instead of username, will resolve later
    setDisplayName(display_name);
    setmyRating(responseData.data.user[0].fields.gameplay_rating);
    // console.log(
    //   'customRules : ',
    //   responseData.data.user[0].fields.custom_rules,
    // );
    setCustomRules(JSON.parse(responseData.data.user[0].fields.custom_rules));
    if (
      responseData.data.user[0].fields.custom_rules.includes(
        'games_reviewed_rule',
      )
    ) {
      CheckCircleChessGamesAnalyzedRule({userId: user_id});
    }
    setUserId(user_id);
    const is_staff = responseData.data.user[0]['fields'].is_staff;
    // console.log('is_staff is : ', is_staff);
    setIsStaffNew(is_staff);
    // setIsStaff(false);
    setEnableLearn(is_staff);
  };

  const handleOnError = responseData => {};

  const {
    mutate: getProfile,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useGetProfileApi(handleProfileGetSuccess, handleOnError);

  const setAuthorizationToken = async () => {
    const token = await getLocalStorage('access_token');
    if (token) {
      setAccessToken(`Bearer ${token}`);
    }
  };

  useEffect(() => {
    console.log('Getting profile data .. ');
    setSuccess(false);
    getProfile();
    console.log('Getting access token .. ');
    setAuthorizationToken();

    const getLastGameTimeControl = async () => {
      const timeControlData = await getLocalStorage('lastGameTimeControl');
      console.log(
        'Last game move timer is : ',
        JSON.stringify(timeControlData),
      );
      if (timeControlData) {
        setMoveTimer(timeControlData.moveTimer);
        setIncrement(timeControlData.Increment);
        let timecontrol =
          (timeControlData.moveTimer / 1000).toString() +
          ' + ' +
          (timeControlData.Increment / 1000).toString();
        setTimeControl(timecontrol);
      }
    };
    const getopponent = async () => {
      const opponentData = await getLocalStorage('opponent');
      console.log('opponent is : ', JSON.stringify(opponentData));
      if (opponentData) {
        setOpponentDisplayName(opponentData.displayName);
        setopponentRating(opponentData.rating);
        setStartfen(opponentData.startfen);
        if (opponentData.startfen.split(' ')[1] == 'b') {
          setIsBlackTurnFirst(true);
        }
        if (opponentData.assistedGameplay) {
          setGamePlayMode('Play and Learn');
        }
        setAssistedGameplay(opponentData.assistedGameplay);
        setGameDetails(opponentData.gameDetails);
      }
    };

    const getMoves = async () => {
      const movesData = await getLocalStorage('moves');
      console.log('moves are : ', JSON.stringify(movesData));
      if (movesData) {
        setMoves(movesData);
      }
    };

    getLastGameTimeControl();
    // getopponent();
    getMoves();
    // return (() => {
    //   debugger
    // })
  }, []);

  const getMoves = async moves => {
    try {
      const prevmoves = await getLocalStorage('moves');

      setTimeout(() => {
        if (prevmoves.length > 0) {
          setMoves(() => {
            const newMoves = moves.map(move => ({
              move: move,
              matched: false,
              isHumanMove: false,
            }));
            // If there are no existing moves, just set the new moves
            // Check if the beginning of newMoves matches prevmoves
            const isPrefix = prevmoves.every(
              (prevMove, index) =>
                newMoves[index] && newMoves[index].move === prevMove.move,
            );

            if (isPrefix) {
              // Append only the new moves that are not in prevmoves
              return [...prevmoves, ...newMoves.slice(prevmoves.length)];
            } else {
              return newMoves;
            }
            // return newMoves;
          });
        }
      }, 1000);
      // return prevmoves; // Return the movesData
    } catch (error) {
      console.error('Error retrieving moves:', error);
      return null; // Return null or handle the error as needed
    }
  };

  useEffect(() => {
    console.log('settinng local storage last game move timer');
    const updateLocalStorage = async () => {
      try {
        await setLocalStorage(
          'lastGameTimeControl',
          {moveTimer: MoveTimer, Increment: Increment},
          false,
          () => {},
        );
      } catch (error) {
        console.error('Failed to set local storage:', error);
      }
    };

    if (MoveTimer) {
      updateLocalStorage();
    }
  }, [game_uuid]);

  useEffect(() => {
    console.log('settinng local storage opponent');
    const updateLocalStorage = async () => {
      try {
        await setLocalStorage(
          'opponent',
          {
            displayName: opponentDisplayName,
            rating: opponentRating,
            startfen: startfen,
            assistedGameplay: assistedGameplay,
            gameDetails: gameDetails,
          },
          false,
          () => {},
        );
      } catch (error) {
        console.error('Failed to set local storage opponent:', error);
      }
    };

    if (opponentDisplayName) {
      updateLocalStorage();
    }
  }, [opponentDisplayName, assistedGameplay, gameDetails]);

  const getChessMoveNumber = move_index => {
    const moveNumber = isBlackTurnFirst
      ? Math.ceil((move_index - 1) / 2) + 1
      : Math.floor((move_index - 1) / 2) + 1;
    const color = isBlackTurnFirst
      ? (move_index - 1) % 2 === 0
        ? '...'
        : '.'
      : (move_index - 1) % 2 === 0
      ? '.'
      : '...';
    return `${moveNumber}${color}`;
  };

  const {
    mutate: saveCirclechessGame,
    data: saveCirclechessGameData,
    isLoading: isSaving,
    error: saveError,
  } = useSaveCircleChessGame();

  const handleAssistedGameMoveAnalysisSuccess = data => {
    console.log(
      'API call was successful, received data:',
      JSON.stringify(data),
    );
    if (data && data.hint_response) {
      let mistake = '';
      const move_index = data.move_index - 1;

      const moveNumber = getChessMoveNumber(data.move_index);

      let hintMessage = `${moveNumber} ${data.move} `;
      let hintMessage2 = `${moveNumber} ${data.move} `;

      if (data.blunder) {
        hintMessage += 'is a Blunder! ';
        hintMessage2 += 'is a Blunder! ';
        mistake = 'Blunder';
      } else if (data.mistake) {
        hintMessage += 'is a Mistake! ';
        hintMessage2 += 'is a Mistake! ';
        mistake = 'Mistake';
      } else if (data.inaccuracy) {
        hintMessage += 'is an Inaccuracy! ';
        hintMessage2 += 'is an Inaccuracy! ';
        mistake = 'Inaccuracy';
      }

      if (data.hint_response) {
        hintMessage += data.hint_response;
      }
      if (data.hint_response2) {
        hintMessage2 += data.hint_response2;
      }
      setHintMessage(mistake);
      setHint(hintMessage);
      setHint2(hintMessage2);

      console.log('hint is : ', hintMessage);
      setMoves(prevMoves => {
        // Create a new array to update the specific move
        const updatedMoves = [...prevMoves];
        // Update the move at the specified index
        if (move_index >= 0 && move_index < updatedMoves.length) {
          updatedMoves[move_index] = {
            ...updatedMoves[move_index],
            mistake: mistake,
            hint: hintMessage,
            hint2: hintMessage2,
          };
        }
        return updatedMoves;
      });
      // setTimeout(() => {
      //   setHint('');
      // }, 20000);
    }
    // Add additional success handling logic here
  };

  const handleAssistedGameMoveAnalysisError = error => {
    console.error('API call failed with error:', error);
    // Add additional error handling logic here
  };

  const {
    mutate: AssistedGameMoveAnalysis,
    data: AssistedGameMoveAnalysisData,
    isLoading: Analysing,
    error: AnalysingError,
  } = useAssistedGameMoveAnalysisApi(
    handleAssistedGameMoveAnalysisSuccess,
    handleAssistedGameMoveAnalysisError,
  );

  const {
    mutate: CheckCircleChessGamesAnalyzedRule,
    data: CheckCircleChessGamesAnalyzedRuleData,
    isLoading: isChecking,
    error: checkrror,
  } = useCheckCircleChessGamesAnalyzedRule();

  const [showReviewGamesComponent, setShowReviewGamesComponent] =
    useState(false);

  // apis to get tags and game fen for selected tag

  const {
    mutate: GetGameWithTag,
    data: GetGameWithTagData,
    isLoading: GettingGameWithTag,
    error: GettingGameWithTagError,
  } = useGetGameWithTag();

  const [tagsSearchResult, setTagsSearchResult] = useState([]);
  const [gameWithTagFen, setGameWithTagFen] = useState('');
  const {isMobileOrTab} = useMediaQuery();
  // setUsername(user.userid);
  const [whitePlayer, setWhitePlayer] = useState(username);
  const [blackPlayer, setBlackPlayer] = useState('Black');
  const [whiteTime, setWhiteTime] = useState(600);
  const [blackTime, setBlackTime] = useState(600);
  const [timeControl, setTimeControl] = useState('');

  const [whitePiecesCaptured, setWhitePiecesCaptured] = useState([]);
  const [blackPiecesCaptured, setBlackPiecesCaptured] = useState([]);

  const [myTurn, setMyTurn] = useState(true);
  const [isBlackTurnFirst, setIsBlackTurnFirst] = useState(false);

  const [started, setStarted] = useState(false);
  const [Result, setResult] = useState('');

  const [position, setPosition] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );
  const [previousFen, setPreviousFen] = useState('');
  const [pgn, setPGN] = useState('');
  const [moves, setMoves] = useState([]);
  const [preMove, setPreMove] = useState(null);

  // Overall state of the game
  const [game, setGame] = useState(new Chess(position));

  const [tableId, setTableId] = useState(-1);

  const [showDrawRequest, setShowDrawRequest] = useState(false);
  const [drawRequestSent, setDrawRequestSent] = useState(false);
  const [showDrawReject, setShowDrawReject] = useState(false);
  const [showAbort, setShowAbort] = useState(false);

  const [showChallengeRequest, setShowChallengeRequest] = useState(false);
  const [showChallengeReject, setShowChallengeReject] = useState(false);
  const [showChallengeExpire, setShowChallengeExpire] = useState(false);
  const [showChallengeCancel, setShowChallengeCancel] = useState(false);
  const [showPlayerNotFound, setShowPlayerNotFound] = useState(false);
  const [showIncorrectHintModal, setShowIncorrectHintModal] = useState(false);

  const [challengeInProgress, setChallengeInProgress] = useState(false);

  const [ChallengeOpponent, setChallengeOpponent] = useState('');
  const [ChallengeMoveTimer, setChallengeMoveTimer] = useState('');
  const [ChallengeIncrement, setChallengeIncrement] = useState('');
  const [ChallengeVariantId, setChallengeVariantId] = useState('');
  const [ChallengePosition, setChallengePosition] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );
  const [ChallengeWhite, setChallengeWhite] = useState('');

  const [searchPlayersResult, setSearchPlayersresult] = useState('');

  const [MoveTimer, setMoveTimer] = useState(180000);
  const [Increment, setIncrement] = useState(2000);

  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [endGameMessage, setEndGameMessage] = useState('');
  const [endType, setEndType] = useState('');

  const [myTime, setmyTime] = useState(null); //  seconds for white
  const [opponentTime, setopponentTime] = useState(null); //  seconds for black
  const [timerId, setTimerId] = useState(null);

  const [gameStartTime, setGameStartTime] = useState(5000);
  const [startTimer, setStartTimer] = useState(null);
  const [firstMoveTimer, setFirstMoveTimer] = useState(0);
  const [firstMovestartTimer, setFirstMoveStartTimer] = useState(null);
  const [searching, setSearching] = useState(false);

  // Notation component props
  const [moveClicked, setMoveClicked] = useState(false);
  const [moveSelected, setMoveSelected] = useState({});
  const [startfen, setStartfen] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );

  const [customRules, setCustomRules] = useState([]);
  const [thinkingTime, SetThinkingTime] = useState(false);

  const [myRating, setmyRating] = useState(0); //  seconds for white
  const [opponentRating, setopponentRating] = useState(0);
  const [myRatingChange, setmyRatingChange] = useState(0); //  seconds for white
  const [opponentRatingChange, setopponentRatingChange] = useState(0);
  const [foundOpponent, SetFoundOpponent] = useState(false);

  //Assisted Gameplay
  const [hint, setHint] = useState('');
  const [hint2, setHint2] = useState('');
  const [moveIndex, setMoveIndex] = useState(0);
  const [showAssitedGameplay, setShowAssitedGameplay] = useState(false);

  //TouchPiece control
  const [restrictedPiece, setRestrictedPiece] = useState('');
  const restrictedPieceRef = useRef(restrictedPiece);
  const [illegalTouchPieceCount, setIllegalTouchPieceCount] = useState(0);
  const [touchPieceControl, setTouchPieceControl] = useState(false);

  // 0: No game in progress
  // 1: Game in starting state
  // 2: Game in progress
  const [gameState, setGameState] = useState(0);

  const startGameStartCountdown = () => {
    const timerId = setInterval(updateGameStartCountdown, 1000);

    // Set a timeout to clear the interval after 25 seconds
    setTimeout(() => {
      clearInterval(timerId); // Clear the interval
      setGameState(prev => 2);
      setStartTimer(null); // Reset the timer state
    }, 2800); // 25 seconds

    // Store the timer ID in state for potential cleanup or future reference
    setStartTimer(timerId);
  };

  useEffect(() => {
    setShowChange(false);
    setGoodFeedback(0);
    setBadFeedback(0);
    if (hint) {
      setShowIncorrectHintModal(true);
    }
  }, [hint]);

  useEffect(() => {
    const updateLocalStorage = async () => {
      try {
        await setLocalStorage('moves', moves, false, () => {});
      } catch (error) {
        console.error('Failed to set localstorage for moves:', error);
      }
    };

    if (moves) {
      updateLocalStorage();
    }
  }, [moves]);

  const assistedGameplayRef = useRef(assistedGameplay);

  useEffect(() => {
    assistedGameplayRef.current = assistedGameplay;
  }, [assistedGameplay]);

  const lastMoveRef = useRef(lastMove);

  useEffect(() => {
    lastMoveRef.current = lastMove;
  }, [lastMove]);

  function getAssistedGameMoveAnalysis(
    userid,
    move,
    fen,
    color,
    last_fen,
    player,
    uuid,
    move_index,
  ) {
    if (assistedGameplayRef.current) {
      setUuid(uuid);
      AssistedGameMoveAnalysis({
        userid,
        move,
        fen,
        color,
        last_fen,
        player,
        uuid,
        move_index,
      });
    }
  }

  const updateGameStartCountdown = () => {
    let currentCountDown = 0;
    setGameStartTime(prevTime => {
      currentCountDown = prevTime - 1000;
      return prevTime - 1000;
    });
    if (currentCountDown == 0) {
      setStartTimer(timer => {
        clearInterval(timer);
        setGameState(prev => 2);
        return timer;
      });
    }
  };

  const stopTimer = () => {
    clearInterval(timerId); // Clear the interval
    setTimerId(null); // Reset timer ID
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const CCApplicationClient = () => {
    if (isStaff && username) {
      console.log('Async .. Logging in with username: ', username, accessToken);
      let tableid;
      let white;
      let total_time;
      let cc_playing;

      const cc_client = socket;

      // Register schemas and handlers using new registerMessageType and registerMessageHandler

      if (startGameResponseCallback) {
        cc_client.registerMessageType(
          'start_game_response',
          schema.startGameResMsgSchema,
        );
        cc_client.registerMessageHandler(
          'start_game_response',
          function (socket, data) {
            // console.log('*** startGameResponseCallback = ', data);
            startGameResponseCallback(socket, data);
          },
        );
      }

      if (registerJoinGameSchema) {
        cc_client.registerMessageType(
          'join_game_request',
          schema.joinGameReqMsgSchema,
        );
        cc_client.registerMessageHandler(
          'join_game_request',
          function (socket, data) {
            registerJoinGameSchema(socket, data);
          },
        );
      }

      if (registerLeaveTableSchema) {
        cc_client.registerMessageType(
          'leave_table_request',
          schema.leaveTableReqMsgSchema,
        );
        cc_client.registerMessageHandler(
          'leave_table_request',
          function (socket, data) {
            registerLeaveTableSchema(socket, data);
          },
        );
      }

      if (challengeCancelCallback) {
        cc_client.registerMessageType(
          'challenge_cancel',
          schema.challengeGameMsgSchema,
        );
        cc_client.registerMessageHandler(
          'challenge_cancel',
          function (socket, data) {
            challengeCancelCallback(socket, data);
          },
        );
      }

      if (registerChallengeRequest) {
        cc_client.registerMessageType(
          'challenge_game_request',
          schema.challengeGameMsgSchema,
        );
        cc_client.registerMessageHandler(
          'challenge_game_request',
          function (socket, data) {
            // console.log('About to call challenge response message handler ... ');
            registerChallengeRequest(socket, data);
          },
        );
      }

      if (joinGameResponseCallback) {
        cc_client.registerMessageType(
          'join_game_response',
          schema.joinGameResMsgSchema,
        );
        cc_client.registerMessageHandler(
          'join_game_response',
          function (socket, data) {
            joinGameResponseCallback(socket, data);
          },
        );
      }

      if (gameReadyResponseCallback) {
        cc_client.registerMessageType(
          'game_ready_response',
          schema.gameReadyResMsgSchema,
        );
        cc_client.registerMessageHandler(
          'game_ready_response',
          function (socket, data) {
            gameReadyResponseCallback(socket, data);
          },
        );
      }

      // if (preStartGameResponseCallback) {
      //   cc_client.registerMessageType('pre_start_game', schema.preStartGameMsgSchema);
      //   cc_client.registerMessageHandler('pre_start_game', function (socket, data) {
      //     preStartGameResponseCallback(socket, data);
      //   });
      // }

      if (playerTurnResponseCallback) {
        cc_client.registerMessageType(
          'player_turn_response',
          schema.eventResMsgSchema,
        );
        cc_client.registerMessageHandler(
          'player_turn_response',
          function (socket, data) {
            playerTurnResponseCallback(socket, data);
          },
        );
      }

      if (registerPlayerMoveSchema) {
        cc_client.registerMessageType('player_move', schema.moveResMsgSchema);
        cc_client.registerMessageHandler(
          'player_move',
          function (socket, data) {
            registerPlayerMoveSchema(socket, data);
          },
        );
      }

      if (startGameCallback) {
        cc_client.registerMessageType('start_game', schema.startGameMsgSchema);
        cc_client.registerMessageHandler('start_game', function (socket, data) {
          startGameCallback(socket, data);
        });
      }

      if (drawCheckResponseCallback) {
        cc_client.registerMessageType('draw_check', schema.drawCheckMsgSchema);
        cc_client.registerMessageHandler('draw_check', function (socket, data) {
          drawCheckResponseCallback(socket, data);
        });
      }

      if (drawRejectedCallback) {
        cc_client.registerMessageType(
          'draw_reject',
          schema.drawRejectMsgSchema,
        );
        cc_client.registerMessageHandler(
          'draw_reject',
          function (socket, data) {
            drawRejectedCallback(socket, data);
          },
        );
      }

      // if (challengeRequestCallback) {
      //   console.log('Inside challenge Request Check .. ');
      //   cc_client.registerMessageType('challenge_request_check', schema.challengeGameMsgSchema);
      //   cc_client.registerMessageHandler('challenge_request_check', function (socket, data) {
      //     console.log('Inside challenge_request_check .... ');
      //     challengeRequestCallback(socket, data);
      //   });
      // }

      if (challengeRejectedCallback) {
        cc_client.registerMessageType(
          'challenge_reject',
          schema.challengeGameMsgSchema,
        );
        cc_client.registerMessageHandler(
          'challenge_reject',
          function (socket, data) {
            challengeRejectedCallback(socket, data);
          },
        );
      }

      if (challengeExpiredCallback) {
        cc_client.registerMessageType(
          'challenge_request_expire',
          schema.challengeGameMsgSchema,
        );
        cc_client.registerMessageHandler(
          'challenge_request_expire',
          function (socket, data) {
            challengeExpiredCallback(socket, data);
          },
        );
      }

      if (registerOnlinePlayersSearchSchema) {
        cc_client.registerMessageType(
          'online_players_search',
          schema.onlinePlayersSearchMsgSchema,
        );
        cc_client.registerMessageHandler(
          'online_players_search',
          function (socket, data) {
            registerOnlinePlayersSearchSchema(socket, data);
          },
        );
      }

      if (registerFetchGameStateSchema) {
        cc_client.registerMessageType(
          'fetch_game_state',
          schema.fetchGameStateMsgSchema,
        );
        cc_client.registerMessageHandler(
          'fetch_game_state',
          function (socket, data) {
            registerFetchGameStateSchema(socket, data);
          },
        );
      }

      if (onlinePlayersSearchResult) {
        cc_client.registerMessageType(
          'online_players_result',
          schema.onlinePlayersResultMsgSchema,
        );
        cc_client.registerMessageHandler(
          'online_players_result',
          function (socket, data) {
            console.log('Online players result = ', JSON.stringify(data));
            onlinePlayersSearchResult(socket, data);
          },
        );
      }

      if (gameEndResponseCallback) {
        cc_client.registerMessageType(
          'game_end_response',
          schema.gameEndResMsgSchema,
        );
        cc_client.registerMessageHandler(
          'game_end_response',
          function (socket, data) {
            gameEndResponseCallback(socket, data);
          },
        );
      }

      setClient(cc_client);
      setIsConnected(cc_client.connected);

      cc_client.on('disconnect', function () {
        setIsConnected(false);
      });

      cc_client.on('connect', function () {
        setIsConnected(true);
      });

      cc_client.on('close', function () {
        setIsConnected(false);
      });

      function startGameResponseCallback(socket, data) {
        console.info('... start game response event .. ', JSON.stringify(data), socket.id);

        setPlaying(true);
        cc_playing = true;

        setShowEndGameModal(false);
        setShowChallengeReject(false);
        setShowChallengeExpire(false);
        setShowChallengeCancel(false);

        // console.log('Received TableID = ', data?.tableId);
        setTableId(data?.tableId);
        tableid = data.tableId;

        white = data.tossWinner;
        const player1 = data.playerList[0].split(':')[0];
        const player2 = data.playerList[1].split(':')[0];

        const black = player1 == white ? player2 : player1;

        setWhitePlayer(white); // white player
        setBlackPlayer(black);

        total_time = data.playerMoveTimer / 1000;
        setFirstMoveTimer(total_time - 20);
        let parsedTouchPiece = data.touchPiece
          ? JSON.parse(data.touchPiece)
          : '';

        setTimeout(() => {
          setRestrictedPiece(parsedTouchPiece);
        }, 1200);

        console.info("inside start game response event handler 1");

        if (data.requestId == 'On reconnect' || data.requestId == 'On fetch') {
          setOpponentDisplayName(data.opponentusername);
          setopponentRating(data.opponentRating);
          setStartfen(data.startFen);
          console.info("inside start game response event handler 2");
          if (data.startFen.split(' ')[1] == 'b') {
            setIsBlackTurnFirst(true);
          }
          setGamePlayMode(data.gamePlayMode);
          if (data.gamePlayMode == 'Play and Learn') {
            setAssistedGameplay(true);
          } else {
            setAssistedGameplay(false);
          }

          let rated = data.gamePlayMode == 'Play' ? 'Rated' : 'Unrated';
          if (
            data.opponentusername.startsWith('Caissa') ||
            data.startFen !==
              'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
          ) {
            rated = 'Unrated';
          }
          setGameDetails(
            `${data.playerMoveTimer / 60000} + ${
              data.moveTimeIncrement / 1000
            } • ${rated} • ${classifyTimeControl(
              data.playerMoveTimer,
              data.moveTimeIncrement,
            )}`,
          );
        }

        // TODO: Hard coding game start countdown timer to 5 seconds
        const gameStartCountdown = 3000;

        setMyTurn(username == white);
        console.log('Awaiting player turn .. ');
      }

      // cc_client.registerMessageType('join_game_request', schema.joinGameReqMsgSchema, function (socket, data) {
      //     cc_client.username = username;
      // });

      function registerJoinGameSchema(socket, data) {
        client.username = username;
      }

      // cc_client.registerMessageType('leave_table_request', schema.leaveTableReqMsgSchema, function (socket, data) {
      //     cc_client.username = username;
      // });

      function registerLeaveTableSchema(socket, data) {
        client.username = username;
      }

      function challengeCancelCallback(socket, data) {
        console.info(
          '...challenge_cancel message data...',
          JSON.stringify(data),
          socket.id
        );

        cc_client.username = username;
        setShowChallengeCancel(true && !cc_playing);
        gameplayStore?.setShowChallengeCancel(true);
        setShowChallengeRequest(false);
      }

      // cc_client.registerMessageType('challenge_game_request', schema.challengeGameMsgSchema, function (socket, data) {
      //     cc_client.username = username;
      //     console.log('...challenge_cancel message data...', data);
      //     setShowChallengeCancel(true);
      //     setShowChallengeRequest(false);
      // });

      function registerChallengeRequest(socket, data) {
        cc_client.username = username;

        setShowChallengeCancel(true);
        setShowChallengeRequest(false);
      }

      function joinGameResponseCallback(socket, data) {
        joinGameHandler(data);
        console.log('Received TableID = ', data?.tableId);
        setTableId(data?.tableId);
      }

      function gameReadyResponseCallback(socket, data) {
        // already seated on new table

        SetFoundOpponent(true);
        setGameStartTime(2000);

        setBlackTime(data.moveTimer);
        setWhiteTime(data.moveTimer);
        setOpponentDisplayName(
          data.opponentusername == displayName
            ? data.username
            : data.opponentusername,
        );
        white = data.tossWinner;
        const black = data.userId == white ? data.opponentUserId : data.userId;

        setWhitePlayer(white); // white player
        setBlackPlayer(black);
        setFirstMoveTimer(data.moveTimer - 20);
        setMoves([]);
        game?.load(data?.data);
        setPosition(game?.fen());
        // setPosition(ChallengePosition ? ChallengePosition : "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1");
        let custom_rules_map = JSON.parse(data.customRulesMap);
        // setCustomRules(custom_rules_map.hasOwnProperty(username) ? custom_rules_map[username] : []);

        console.info('... game ready response event .. ', JSON.stringify(data), socket.id);
        // console.log('Setting up new FEN : ', data?.data);
        // game?.load(data?.data);
        // setPosition(game?.fen());

        setmyRating(
          data.opponentusername == displayName
            ? data.opponentRating
            : data.userRating,
        );
        setopponentRating(
          data.opponentusername == displayName
            ? data.userRating
            : data.opponentRating,
        );
        setStartfen(data.data);
        if (data.data.split(' ')[1] == 'b') {
          setIsBlackTurnFirst(true);
        } else {
          setIsBlackTurnFirst(false);
        }
        setTimeout(() => {
          setSearching(false);
          setStarted(true);
          setGameState(1);
          startGameStartCountdown();
          setChallengeInProgress(false);
        }, 1500);
      }

      // function preStartGameResponseCallback(socket, data) {
      //   // already seated on new table
      //   setSearching(false);
      //   setGameStartTime(5000);
      //   setGameState(1);
      //   startGameStartCountdown();

      //   console.log('... game ready response event .. ', data);
      //   console.log('Setting up new FEN : ', data?.data);
      //   game?.load(data?.data);
      //   setWhitePlayer(data.white); // white player
      //   setBlackPlayer(data.black);

      //   setPosition(game?.fen());
      //   setBlackTime(data.timerValue);
      //   setWhiteTime(data.timerValue);
      // }

      function playerTurnResponseCallback(socket, data) {
        console.info('... player turn event .. ', JSON.stringify(data), socket.id);
        setMyTurn(data.userId === username);

        if (data.userId === username) {
          setPreviousFen(data.previousFen);
          console.log('Setting up new FEN : ', data?.data);
          game?.load(data?.data);
          setPosition(game?.fen());
          console.info("inside player turn response event handler 1");
          if (data.requestId !== 'on connect') {
            setTimeout(() => {
              setMoves(prevMoves => {
                const newMoves = data.moves.map(move => ({
                  move: move,
                  matched: false,
                  isHumanMove: false,
                }));
                // If there are no existing moves, just set the new moves
                if (prevMoves.length === 0) {
                  return newMoves;
                }
                // Add the last move in data.moves to the end of the list
                // if (data.moves.length > prevMoves.length) {
                //   const newMove = {
                //     move: data.moves[data.moves.length - 1],
                //     matched: false,
                //     isHumanMove: false,
                //   }
                //   return [
                //     ...prevMoves,
                //     newMove
                //   ];
                // }
                const isPrefix = prevMoves.every(
                  (prevMove, index) =>
                    newMoves[index] && newMoves[index].move === prevMove.move,
                );

                if (isPrefix) {
                  // Append only the new moves that are not in prevmoves
                  return [...prevMoves, ...newMoves.slice(prevMoves.length)];
                } else {
                  return newMoves;
                }
              });
            }, 500);
          } else {
            getMoves(data.moves);
          }
        } else {
          if (data.requestId !== 'on connect') {
            setMoves(prevMoves => {
              const newMoves = data.moves.map(move => ({
                move: move,
                matched: false,
                isHumanMove: false,
              }));
              // If there are no existing moves, just set the new moves
              if (prevMoves.length === 0) {
                return newMoves;
              }
              // Add the last move in data.moves to the end of the list
              // if (data.moves.length > prevMoves.length) {
              //   const newMove = {
              //     move: data.moves[data.moves.length - 1],
              //     matched: false,
              //     isHumanMove: false,
              //   }
              //   return [
              //     ...prevMoves,
              //     newMove
              //   ];
              // }
              // return newMoves;
              const isPrefix = prevMoves.every(
                (prevMove, index) =>
                  newMoves[index] && newMoves[index].move === prevMove.move,
              );

              if (isPrefix) {
                // Append only the new moves that are not in prevmoves
                return [...prevMoves, ...newMoves.slice(prevMoves.length)];
              } else {
                return newMoves;
              }
            });
          } else {
            getMoves(data.moves);
            setPreviousFen(data.previousFen);
            console.log('Setting up new FEN : ', data?.data);
            game?.load(data?.data);
            setPosition(game?.fen());
          }
        }

        const move = data.moves[data.moves.length - 1];

        if (move == lastMoveRef.current) {
          setLastMoveSent(true);
          console.info('last move played by user is', lastMoveRef.current);
          if (lastMovetimeoutRef.current) {
            clearTimeout(lastMovetimeoutRef.current);
            lastMovetimeoutRef.current = null; // Clear the reference
          }
        } else {
          setLastMoveSent(false);
        }

        // Determine the player color based on the move index
        const move_index = data.moves.length;
        const color = white === username ? 'white' : 'black';

        getAssistedGameMoveAnalysis(
          username,
          move,
          data?.data,
          color,
          data.previousFen,
          data.userId === username ? 'opponent' : 'me',
          tableid,
          move_index,
        );
        setMoveIndex(move_index);

        setBlackTime(data.blackTime);
        setWhiteTime(data.whiteTime);
        // setTimeout(() => {
        //     setMoves(data.moves);
        // }, (500));

        setWhitePiecesCaptured(
          data.whitePiecesCaptured.map(letter => letter.toUpperCase()),
        );
        setBlackPiecesCaptured(
          data.blackPiecesCaptured.map(letter => letter.toUpperCase()),
        );
        setShowAbort(
          data.moves.length > 1 ||
            (data.moves.length == 1 && !(data.userId === username))
            ? false
            : true,
        );
        console.info("inside player turn response event handler 2");
        if (data.moves.length == 1) {
          clearInterval(firstMovestartTimer);
          setFirstMoveStartTimer(null);
        }
        let my_time = username == white ? data.whiteTime : data.blackTime;
        if (
          data.moves.length > 10 &&
          my_time > total_time / 10 &&
          data.userId === username
        ) {
          SetThinkingTime(true);
          setTimeout(() => {
            SetThinkingTime(false);
          }, (total_time / 60) * 1000); // 5000 milliseconds = 5 seconds
        }

        setStarted(true);

        console.log('position is set :', position);
        console.log('New game FEN = ', game.fen());
      }

      // cc_client.registerMessageType('player_move', schema.moveResMsgSchema, function (socket, data) {
      //     console.log(' ... Player played move ... ', data);
      // });
      function registerPlayerMoveSchema(socket, data) {
        console.info(' ... Player played move ... ', JSON.stringify(data), socket.id);
      }

      function startGameCallback(socket, data) {
        console.info(' ... Start game notification ... ', JSON.stringify(data));
        setStarted(true);
        setPlaying(true);
      }

      function drawCheckResponseCallback(socket, data) {
        console.info('...DrawCheck message data...', JSON.stringify(data), socket.id);
        if (data.userId == username && data.tableId == tableid) {
          setShowDrawRequest(true);
        } // Show the draw request modal
      }

      function drawRejectedCallback(socket, data) {
        console.info('...draw_reject message data...', JSON.stringify(data), socket.id);
        if (data.tableId == tableid) {
          setDrawRequestSent(false);
          if (data.userId == username) {
            setShowDrawReject(true);
            const timer = setTimeout(() => {
              setShowDrawReject(false);
            }, 2000);

            // Cleanup the timeout if the component unmounts or if drawRequestSent changes
            //   return () => clearTimeout(timer);
          }
        }
      }

      function challengeRequestCallback(socket, data) {
        console.info(
          '...challenge_request_check message data...',
          JSON.stringify(data),
        );
        navigation.navigate('PlayChess');
        if (data.userId == username) {
          setShowChallengeRequest(true && !cc_playing);
          setChallengeOpponent(data.opponent);
          setChallengeVariantId(data.variantId);
          setChallengeMoveTimer(data.playerMoveTimer);
          setChallengeIncrement(data.moveTimeIncrement);
          game?.load(
            data?.gameState ||
              'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
          );
          if (
            game?.fen() !=
            'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
          ) {
            setPosition(game?.fen());
          }
          setChallengePosition(game?.fen());
          setChallengeWhite(data.white);
          // setBlackPlayer(data.black);
          setShowEndGameModal(false);
          setShowChallengeReject(false);
          setShowChallengeExpire(false);
          setShowChallengeCancel(false);
        } // Show the challenge request modal
      }

      function challengeRejectedCallback(socket, data) {
        console.info(
          '...challenge_reject message data...',
          JSON.stringify(data), socket.id
        );
        setShowChallengeReject(true);
        gameplayStore?.setShowChallengeReject(true);
        setChallengeInProgress(false);
      }

      function challengeExpiredCallback(socket, data) {
        console.info(
          '...challenge_reject message data...',
          JSON.stringify(data), socket.id
        );
        if (data.userId == username) {
          setShowChallengeExpire(true);
        }
        gameplayStore?.setShowChallengeExpire(true);
        setShowChallengeRequest(false);
        setChallengeInProgress(false);
      }

      // cc_client.registerMessageType('online_players_search', schema.onlinePlayersSearchMsgSchema, function (socket, data) {
      //     cc_client.username = username;
      // });

      function registerOnlinePlayersSearchSchema(socket, data) {
        cc_client.username = username;
      }

      function registerFetchGameStateSchema(socket, data) {
        cc_client.username = username;
      }

      function onlinePlayersSearchResult(socket, data) {
        console.info(
          '... online_players_result message data...',
          JSON.stringify(data), socket.id
        );
        setSearchPlayersresult(data.players);
      }

      function gameEndResponseCallback(socket, data) {
        console.info(' ... end game response ... ', JSON.stringify(data), socket.id);
        console.info("tableId set on client : ", tableid);
        if (data.tableId == tableid || 1) {
          game?.load(data?.gameState);
          setPosition(game?.fen());
          setEndType(data.endType);
          console.info(' ... end game response ...  1')
          if (data.winner == username) {
            if (
              data.endType == 'draw' ||
              data.endType == 'stalemate' ||
              data.endType == 'insufficient' ||
              data.endType == 'repetition'
            ) {
              setResult('Draw');
              setEndGameMessage('The match ended in a draw.');
            } else if (data.endType == 'abort') {
              setResult('Abort');
              setEndGameMessage('The match was aborted.');
              setmyRatingChange(0);
              setopponentRatingChange(0);
            } else {
              setResult('Win');
              setEndGameMessage('Congratulations! You won the match!');
              if (data.endType == 'timeup') {
                setopponentTime(0);
              }
            }
            setmyRating(data.winner_rating);
            setGameUuid(data.winner_gameId);
            setmyRatingChange(parseInt(data.winner_rating_delta));
            setopponentRating(data.opponent_rating);
            setopponentRatingChange(parseInt(data.opponent_rating_delta));
          } else if (
            data.endType == 'draw' ||
            data.endType == 'stalemate' ||
            data.endType == 'insufficient' ||
            data.endType == 'repetition'
          ) {
            setResult('Draw');
            setEndGameMessage('The match ended in a draw.');
            setmyRating(data.opponent_rating);
            setmyRatingChange(parseInt(data.opponent_rating_delta));
            setGameUuid(data.opponent_gameId);
            setopponentRating(data.winner_rating);
            setopponentRatingChange(parseInt(data.opponent_rating_delta));
          } else if (data.endType == 'abort') {
            setResult('Abort');
            setEndGameMessage('The match was aborted.');
            setmyRatingChange(0);
            setopponentRatingChange(0);
          } else {
            setResult('Loss');
            setEndGameMessage('Sorry, you lost the match.');
            if (data.endType == 'timeup') {
              setmyTime(0);
            }
            setmyRating(data.opponent_rating);
            setmyRatingChange(parseInt(data.opponent_rating_delta));
            setGameUuid(data.opponent_gameId);
            setopponentRating(data.winner_rating);
            setopponentRatingChange(parseInt(data.opponent_rating_delta));
          }
          console.info(' ... end game response ... 2')
          setPGN(data.pgn);
          setMoves(prevMoves => {
            const newMoves = data.moves.map(move => ({
              move: move,
              matched: false,
              isHumanMove: false,
            }));
            // If there are no existing moves, just set the new moves
            if (prevMoves.length === 0) {
              return newMoves;
            }

            if (prevMoves.length === data.moves.length) {
              return prevMoves;
            }

            // Add the last move in data.moves to the end of the list
            if (data.moves.length > prevMoves.length) {
              const newMove = {
                move: data.moves[data.moves.length - 1],
                matched: false,
                isHumanMove: false,
              };
              return [...prevMoves, newMove];
            }
            return prevMoves;
          });
          console.info(' ... end game response ... 3')
          setWhitePiecesCaptured(
            data.whitePiecesCaptured.map(letter => letter.toUpperCase()),
          );
          setBlackPiecesCaptured(
            data.blackPiecesCaptured.map(letter => letter.toUpperCase()),
          );
          setChallengeMoveTimer(data.playerMoveTimer);
          setChallengeIncrement(data.moveTimeIncrement);
          setChallengeWhite(
            data.white == data.userId ? data.opponent : data.userId,
          );
          setMoveTimer(data.playerMoveTimer);
          setIncrement(data.moveTimeIncrement);
          setChallengeOpponent(
            data.opponent == username ? data.winner : data.opponent,
          );

          setPlaying(false);
          cc_playing = false;
          setDrawRequestSent(false);
          // setMyTurn(false);

          setShowEndGameModal(true);
          gameplayStore?.setShowEndGameModal(true);
          SetFoundOpponent(false);
          console.info(' ... end game response ... 4')

          setHint('');
          setHint2('');
          setIsBlackTurnFirst(false);
          console.log('reset restricted piece');
          setRestrictedPiece('');
        }
        // Reset all game flags
        // resetGameFlags();
      }
    }
  };

  const cancelJoinGameRequest = () => {
    // Cancelling join game request and unseating from table tableId

    client.username = username;

    let packet = {};
    packet.requestId = new Date().getTime().toString();
    packet.type = 'leave_table_request';
    packet.tableId = tableId;

    console.info('Sending request to leave table .. ', JSON.stringify(packet), client.id);

    // Send join game request
    client.send_msg('leave_table_request', packet);

    setSearching(false);
    setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  };

  function joinGame(moveTimer, increment) {
    setSearching(true);
    setShowEndGameModal(false);
    setStarted(false);
    setChallengeInProgress(false);

    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }

    client.username = username;

    let packet = {};
    setCntr(cntr + 1);
    packet.requestId = new Date().getTime().toString();
    packet.type = 'join_game_request';
    packet.variantId = 1;
    packet.username = username;
    packet.message = '';
    packet.playerMoveTimer = moveTimer;
    packet.moveTimeIncrement = increment;
    packet.gamePlayMode = gamePlayMode;
    let timecontrol =
      (moveTimer / 1000).toString() + ' + ' + (increment / 1000).toString();
    setTimeControl(timecontrol);

    setMoveTimer(moveTimer);
    setIncrement(increment);

    setChallengeMoveTimer(moveTimer);
    setChallengeIncrement(increment);

    console.info('Sending request to join game .. ', JSON.stringify(packet), client.id);
    console.log('timeControl set to : ', timeControl);
    // Send join game request
    client.send_msg('join_game_request', packet);
  }

  function challengeGame(
    opponent,
    ChallengeMoveTimer,
    ChallengeIncrement,
    white = '',
    fen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  ) {
    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }
    setShowEndGameModal(false);
    setStarted(false);
    client.username = username;

    let packet = {};
    setCntr(cntr + 1);
    setChallengeOpponent(opponent + ':' + opponentDisplayName);
    packet.requestId = new Date().getTime().toString();
    packet.type = 'challenge_game_request';
    packet.variantId = 1;
    packet.username = username;
    packet.opponent = opponent + ':' + opponentDisplayName;
    packet.playerMoveTimer = ChallengeMoveTimer;
    packet.moveTimeIncrement = ChallengeIncrement;
    packet.gameState = fen;
    packet.white = white;
    packet.black = '';
    packet.gamePlayMode = gamePlayMode;
    let timecontrol =
      (ChallengeMoveTimer / 1000).toString() +
      ' + ' +
      (ChallengeIncrement / 1000).toString();
    setTimeControl(timecontrol);
    console.info('Sending challenge game request  .. ', JSON.stringify(packet));
    setChallengeMoveTimer(ChallengeMoveTimer);
    setChallengeIncrement(ChallengeIncrement);

    // Send join game request
    client.send_msg('challenge_game_request', packet);
    console.log(ChallengeOpponent);

    setChallengeInProgress(true);
  }

  function drawRequest() {
    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }

    // client.username = username;

    let packet = {};
    // setCntr(cntr + 1);
    packet.requestId = new Date().getTime().toString();
    packet.username = username;
    packet.type = 'draw_request';
    packet.tableId = tableId;
    packet.move = 'draw';

    console.info('draw request Packet = ', JSON.stringify(packet), client.id);

    // Send join game request
    client.send_msg('player_move', packet);

    setDrawRequestSent(true);
  }

  function resign() {
    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }

    // client.username = username;

    let packet = {};
    // setCntr(cntr + 1);
    packet.requestId = new Date().getTime().toString();
    packet.username = username;
    packet.type = 'player_resign';
    packet.tableId = tableId;
    packet.move = 'resign';

    console.info('Resign Packet = ', JSON.stringify(packet), client.id);

    // Send join game request
    client.send_msg('player_move', packet);
  }

  function searchPlayers(pattern) {
    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }

    let packet = {};
    packet.requestId = new Date().getTime().toString();
    packet.userId = username;
    packet.type = 'online_players_search';
    packet.pattern = pattern;

    console.info(
      'searching players by username Packet = ',
      JSON.stringify(packet), client.id
    );
    setSearchPlayersresult('');
    setOpponentDisplayName('');

    client.send_msg('online_players_search', packet);
  }

  function abort() {
    if (!client) {
      // something is wrong here
      window.alert('Refresh page to re-connect with server!');
      return;
    }

    // client.username = username;
    console.log('inside abort');
    let packet = {};
    // setCntr(cntr + 1);
    packet.requestId = new Date().getTime().toString();
    packet.username = username;
    packet.type = 'game_abort';
    packet.tableId = tableId;
    packet.move = 'abort';

    console.info('Abort Packet = ', JSON.stringify(packet), client.id);

    // Send join game request
    client.send_msg('player_move', packet);
  }

  const makeMove = (s, d, p) => {
    if (myTurn) {
      game?.move({
        from: s,
        to: d,
        promotion: p,
      });

      const move = game.history().slice(-1)[0];

      let packet = {};
      packet.requestId = new Date().getTime().toString();
      packet.username = username;
      packet.type = 'player_move';
      packet.tableId = tableId;
      packet.move = move;

      console.info('Move Packet = ', JSON.stringify(packet), client.id);

      if (showDrawRequest) {
        // A move was made while the player was offered a draw request
        // The user ignored the draw request, so we send back an implicit decline
        console.info('inside draw reject with move....');
        handleDrawResponse(false);
        setShowDrawRequest(false);
      }

      setLastMove(move);
      client.send_msg('player_move', packet);

      // retry move if player turn response doesn't acknowledge it
      const timeoutId = setTimeout(() => {
        if (!lastMoveSent) {
          client.send_msg('player_move', packet);
          const timeoutId_2 = setTimeout(() => {
            if (!lastMoveSent) {
              CCApplicationClient();
            }
          }, 1000);
          lastMovetimeoutRef.current = timeoutId_2;
        }
      }, 500);
      lastMovetimeoutRef.current = timeoutId;
    }
    // else{
    //     setPreMove({ from: s, to: d, promotion: p });
    //     console.log('Pre-move set:', { from: s, to: d, promotion: p });
    // }
  };

  const handleDrawResponse = accepted => {
    let packet = {};
    packet.requestId = new Date().getTime().toString();
    packet.username = username;
    packet.type = 'draw_response';
    packet.tableId = tableId;
    if (accepted) {
      packet.move = 'Y';
    } else {
      packet.move = 'N';
    }
    console.info('draw response Packet = ', JSON.stringify(packet), client.id);
    client.send_msg('player_move', packet);

    setShowDrawRequest(false); // Close the draw request modal
  };

  const handleChallengeResponse = accepted => {
    let packet = {};
    packet.requestId = new Date().getTime().toString();
    packet.variantId = ChallengeVariantId;
    packet.userId = username;
    packet.opponent = ChallengeOpponent;
    packet.playerMoveTimer = ChallengeMoveTimer;
    packet.moveTimeIncrement = ChallengeIncrement;
    packet.gameState = ChallengePosition;
    packet.white = ChallengeWhite;
    packet.black = blackPlayer;
    packet.gamePlayMode = gamePlayMode;

    packet.tableId = tableId;
    if (accepted) {
      let timecontrol =
        (ChallengeMoveTimer / 1000).toString() +
        ' + ' +
        (ChallengeIncrement / 1000).toString();
      setTimeControl(timecontrol);
      packet.type = 'challenge_accept_response';
    } else {
      packet.type = 'challenge_reject';
      // setChallengeOpponent('');
      // setChallengeVariantId('');
      // setChallengeMoveTimer('');
      // setChallengeIncrement('');
    }
    console.info('Packet = ', JSON.stringify(packet));
    client.send_msg('challenge_game_request', packet);

    setShowChallengeRequest(false); // Close the Challenge request modal
  };

  const handleChallengeCancel = () => {
    let packet = {};
    packet.requestId = new Date().getTime().toString();
    packet.variantId = ChallengeVariantId;
    packet.userId = username;
    packet.opponent = ChallengeOpponent;
    packet.playerMoveTimer = ChallengeMoveTimer;
    packet.moveTimeIncrement = ChallengeIncrement;
    packet.gameState = ChallengePosition;
    packet.white = ChallengeWhite;
    packet.black = blackPlayer;
    packet.gamePlayMode = gamePlayMode;

    packet.tableId = tableId;
    packet.type = 'challenge_cancel';
    console.info('challenge cancel Packet = ', JSON.stringify(packet), client.id);
    client.send_msg('challenge_game_request', packet);
    setChallengeInProgress(false);
    setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  };

  const handle_end_game_modal = () => {
    setShowEndGameModal(false);
    stopTimer();
    //setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
    //setmyTime(600);
    //setopponentTime(600);
    // setTimeControl('');
    // setStarted(false);
    setSearching(false);
    setChallengeInProgress(false);
    setChallengePosition(
      'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    );
    setShowAssitedGameplay(false);

    // Additional logic to handle modal close if needed
  };

  const handle_challenge_reject = () => {
    setChallengeInProgress(false);

    setShowChallengeReject(false);
    setShowChallengeCancel(false);
    // setChallengeOpponent('');
    // setChallengeVariantId('');
    // setChallengeMoveTimer('');
    // setChallengeIncrement('');
    // setTimeControl('');
    setChallengeInProgress(false);
    setShowChallengeExpire(false);
    setShowPlayerNotFound(false);
    setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
    setChallengePosition(
      'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    );
  };

  const handle_new_game = () => {
    setStarted(false);
    setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
    setShowAssitedGameplay(false);
    setGamePlayMode('Play');
    setAssistedGameplay(false);
  };

  const onGameStart = () => {};

  // Startup script: connect with server and keep the connection intact at startup
  useEffect(() => {
    CCApplicationClient();
    setWhitePlayer(username);
  }, [username, accessToken, socket]);

  useEffect(() => {
    console.log('New Client set to = ', client);
    const joinChallenge = async () => {
      console.log('Joining Challenge from non-gameplay screen ....');
      const challengePacket = await getLocalStorage('challengePacket');
      // console.log(
      //   'Challenge Packet retrieved from LocalStorage = ',
      //   challengePacket,
      // );

      if (challengePacket) {
        setShowChallengeRequest(false);
        setChallengeOpponent(challengePacket.opponent);
        setChallengeVariantId(challengePacket.variantId);
        setChallengeMoveTimer(challengePacket.playerMoveTimer);
        setChallengeIncrement(challengePacket.moveTimeIncrement);
        game?.load(
          challengePacket?.gameState ||
            'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
        );
        if (
          game?.fen() !=
          'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
        ) {
          setPosition(game?.fen());
        }
        setChallengePosition(game?.fen());
        setChallengeWhite(challengePacket.white);
        // setBlackPlayer(data.black);
      }

      setLocalStorage('challengePacket', null);

      // if (client && challengePacket) {
      //   let timecontrol =
      //     (challengePacket.playerMoveTimer / 1000).toString() +
      //     ' + ' +
      //     (challengePacket.moveTimeIncrement / 1000).toString();
      //   setTimeControl(timecontrol);
      //   setTimeout(() => {
      //     client.send_msg('challenge_game_request', challengePacket);
      //   },1500);

      // }
    };

    joinChallenge();
    setShowEndGameModal(false);
    setShowChallengeReject(false);
    setShowChallengeExpire(false);
    setShowChallengeCancel(false);

    if (client && username) {
      client.username = username;
      let packet = {};
      packet.requestId = new Date().getTime().toString();
      packet.type = 'fetch_game_state';
      packet.userId = username;

      console.info('fetch game state packet is :', JSON.stringify(packet), client.id);

      client.send_msg('fetch_game_state', packet);
    }
  }, [client]);

  useEffect(() => {
    console.log('myTurn set to ', myTurn);
    if (whitePlayer == username) {
      setmyTime(whiteTime);
      setopponentTime(blackTime);
    } else {
      setmyTime(blackTime);
      setopponentTime(whiteTime);
    }
    if (!myTurn) {
      console.log('reset restricted piece');
      setRestrictedPiece('');
      // firstMoveCountdown();
    }
  }, [myTurn, whiteTime, blackTime]);

  useEffect(() => {
    if (showEndGameModal) {
      stopTimer();
      console.log('saving circlechess game : ', pgn);
      // let opponentUserId = (
      //   whitePlayer == username ? blackPlayer : whitePlayer
      // ).toString();
      // const now = new Date();
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, '0');
      // const day = String(now.getDate()).padStart(2, '0');
      // const hours = String(now.getHours()).padStart(2, '0');
      // const minutes = String(now.getMinutes()).padStart(2, '0');
      // const seconds = String(now.getSeconds()).padStart(2, '0');

      // let headers = `[Variant "Standard"]\n[TimeControl "${timeControl}"]\n\n`;
      // if (
      //   startfen !== 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
      // ) {
      //   headers = `[Variant "From Position"]\n[TimeControl "${timeControl}"]\n[FEN "${startfen}"]\n\n`;
      // }
      handleClearPremoves();

      // const endTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      // let pgnContent = headers + pgn;
      // let userId = username;
      // let color = whitePlayer == username ? 'White' : 'Black';
      // console.log('pgn is : ', pgnContent);
      // setChallengeOpponent(opponentUserId);
      // if (Result !== 'Abort') {
      //   saveCirclechessGame({
      //     userId,
      //     pgnContent,
      //     opponentUserId,
      //     color,
      //     endTime,
      //     Result,
      //     timeControl,
      //     opponentUsername: opponentDisplayName,
      //     myRating,
      //     opponentRating,
      //     endType,
      //   });
      // }
    }
  }, [showEndGameModal]);

  useEffect(() => {
    console.log(
      'save circle chess data ',
      JSON.stringify(saveCirclechessGameData),
    );
    if (saveCirclechessGameData && saveCirclechessGameData.game_uuid) {
      setGameUuid(saveCirclechessGameData.game_uuid);
      console.log('game_uuid is : ', saveCirclechessGameData.game_uuid);
    }
  }, [saveCirclechessGameData]);
  useEffect(() => {
    console.log(
      'CheckCircleChessGamesAnalyzedRule data ',
      JSON.stringify(CheckCircleChessGamesAnalyzedRuleData),
    );
    if (CheckCircleChessGamesAnalyzedRuleData) {
      setShowReviewGamesComponent(
        !CheckCircleChessGamesAnalyzedRuleData.success,
      );
    }
  }, [CheckCircleChessGamesAnalyzedRuleData]);

  useEffect(() => {
    console.log('GetGameWithTagData data ', JSON.stringify(GetGameWithTagData));
    if (GetGameWithTagData && GetGameWithTagData.fen) {
      setGameWithTagFen(GetGameWithTagData.fen);
      console.log('fen is : ', GetGameWithTagData.fen);
    }
  }, [GetGameWithTagData]);

  const isValidPreMove = (game, from, to) => {
    const allMoves = game.moves({verbose: true});
    return allMoves.some(move => move.from === from && move.to === to);
  };

  // useEffect(() => {
  //     if (playing && myTurn) {
  //         stopTimer()
  //         startmyTimer(); // Start the timer when the game starts
  //         // if(preMove){
  //         //     const { from, to, promotion } = preMove;
  //         //     if (isValidPreMove(game, from, to)) {
  //         //         game?.move({ from, to, promotion });

  //         //         const move = game.history().slice(-1)[0];

  //         //         let packet = {};
  //         //         packet.requestId = new Date().getTime().toString();
  //         //         packet.username = username;
  //         //         packet.type = 'player_move';
  //         //         packet.tableId = tableId;
  //         //         packet.move = move;

  //         //         console.log('Packet = ', packet);

  //         //         client.send_msg('player_move', packet);
  //         //         setPreviousFen(position);
  //         //         setPosition(game?.fen());
  //         //         setPreMove(null);
  //         //     } else {
  //         //         console.log('Invalid pre-move:', preMove);
  //         //         setPreMove(null); // Clear the invalid pre-move
  //         //     }
  //         // }

  //     } else if (playing && !myTurn) {
  //         stopTimer();
  //         startopponentTimer();
  //     }
  //     else {
  //         stopTimer();
  //     }
  //     return () => {
  //         stopTimer(); // Clean up: stop the timer when the component unmounts
  //     };

  // }, [playing, myTurn]);

  const handleChallenge = () => {
    if (ChallengeOpponent.trim() !== '' && ChallengeMoveTimer.trim !== '') {
      challengeGame(ChallengeOpponent, ChallengeMoveTimer, ChallengeIncrement);
    } else {
      // Handle case when opponent's username is empty
      alert(
        "Please enter opponent's username and Time Control for the challenge",
      );
    }
  };
  const {
    mutate: feedBack,
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useAssistedPuzzleFeedback();

  async function handleSubmit(selectedFeedback) {
    try {
      await feedBack({
        feedback: selectedFeedback,
        uuid: uuid,
        userid: userId,
        move_index: moveIndex,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (searching) {
      const timer = setTimeout(() => {
        console.info('join game request expired .... ');
        cancelJoinGameRequest();
        setShowPlayerNotFound(true);
      }, 15000); // 20000 milliseconds = 20 seconds

      // Cleanup the timer if the component unmounts or if `searching` changes
      return () => clearTimeout(timer);
    }
  }, [searching, tableId]);

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    let timeout;
    if (!isConnected) {
      timeout = setTimeout(() => {
        setShowNotification(true);
      }, 3000);
    } else {
      setShowNotification(false);
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isConnected]);

  function isPieceMine(pieceColor) {
    // Check if the username matches the player assigned to the color of the restricted piece
    if (pieceColor === 'w' && whitePlayer === username) {
      return true; // The restricted piece belongs to the current user (White player)
    }
    if (pieceColor === 'b' && blackPlayer === username) {
      return true; // The restricted piece belongs to the current user (Black player)
    }
    return false; // The restricted piece does not belong to the current user
  }

  function canMoveRestrictedPiece(square) {
    console.log('Piece can be moved or not check');
    // Generate all legal moves for the color
    const legalMoves = game.moves({square, verbose: true});

    // Check if there are any legal moves for the piece
    if (legalMoves.length > 0) {
      // The piece can be moved legally
      console.log(`Piece on ${square} can be moved.`);
      return true;
    } else {
      // No legal moves, so increment the illegal touch piece count
      console.log(`Piece on ${square} cannot be moved.`);
      setIllegalTouchPieceCount(prevCount => prevCount + 1);
      return false;
    }
  }

  const onPieceDragBegin = (piece, square) => {
    console.log('Piece drag begin', piece[1], square);
    if (
      customRules.includes('touch_piece_rule') &&
      canMoveRestrictedPiece(square)
    ) {
      setRestrictedPiece({
        type: piece[1].toLowerCase(),
        square: square,
        color: piece[0],
      });
    }
  };

  useEffect(() => {
    console.log(
      'change in restricted piece: ',
      JSON.stringify(restrictedPiece),
    );
    restrictedPieceRef.current = restrictedPiece;
    if (customRules.includes('touch_piece_rule')) {
      let packet = {};
      packet.requestId = new Date().getTime().toString();
      packet.username = username;
      packet.type = 'touch_piece';
      packet.tableId = tableId;
      packet.move = JSON.stringify(restrictedPiece);

      console.info('touch piece Packet = ', JSON.stringify(packet));
      client?.send_msg('player_move', packet);
    }
  }, [restrictedPiece]);

  const restrictPiece = (piece, square) => {
    let boardOrientation = whitePlayer == username ? 'w' : 'b';
    console.log('Piece Clicked', piece, square);
    if (piece) {
      piece.square = square;
    }
    if (
      !restrictedPieceRef.current &&
      customRules.includes('touch_piece_rule') &&
      canMoveRestrictedPiece(square) &&
      piece.color === boardOrientation
    ) {
      setRestrictedPiece(piece);
    }
  };


  const handleContinue = () => {
    gameplayStore.setShowChangeInClient(false);
    client.connect()
    // Logic to continue session on this tab
  };

  const handleClose = () => {
    // gameplayStore.setShowChangeInClient(false);
    navigation.navigate('Dashboard')
    // Logic to close this window or session
  };

  // useEffect(() => {
  //     setIsConnected(client.connected);
  // }, [client, client.connected]);
  return (
    <Box style={{backgroundColor: '#FAF6EB', height: '100%'}}>
      <Box
        style={isMobileOrTab ? styles.containerMobile : styles.container}
        flexDirection={isMobileOrTab ? 'column' : 'row'}
        {...(isMobileOrTab && {overflow: 'scroll'})}
        flex={1}>
        {/* Below is the left pane for the chessboard */}

        {(!isMobileOrTab || playing || started) && (
          <Box style={{flex: isMobileOrTab ? 1 : 0.5}}>
            <Box
              style={[
                styles.chessboardContainer,
                // {flex: isMobileOrTab ? 1 : playing || started ? 0.48 : 0.5},
              ]}>
              {!isConnected && showNotification && (
                <NotificationBox styles={styles} />
              )}

              {started && (
                <Box
                  flexDirection="row"
                  style={{
                    marginBottom: 8,
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}>
                  <CCText>{gameDetails}</CCText>
                  {assistedGameplay && (
                    <Box flexDirection="row" alignItems="center">
                      <CCText style={{marginRight: 18}}>Assisted Gameplay</CCText>
                      {/* <Switch
                        trackColor={{ false: '#767577', true: CCColors.Green }}
                        thumbColor='#f4f3f4'
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => setAssistedGameplay(!assistedGameplay)}
                        value={assistedGameplay}
                    /> */}
                    </Box>
                  )}
                </Box>
              )}
              {isMobileOrTab && (started || playing) && (
                <PlayerComponent
                  flex={2}
                  color={whitePlayer == username ? 'black' : 'white'}
                  username={opponentDisplayName}
                  timer={opponentTime}
                  whitePieces={whitePiecesCaptured}
                  blackPieces={blackPiecesCaptured}
                  firstMoveTimer={firstMoveTimer}
                  showFirstMoveTimer={showAbort && !myTurn}
                  rating={opponentRating}
                  myTurn={!myTurn}
                  playing={playing}
                />
              )}
              {(playing || started) && (
                <ChessboardComponent
                  ref={chessboardRef}
                  disabled={
                    !playing ||
                    (thinkingTime && customRules.includes('thinking_time_rule'))
                  }
                  boardOrientation={whitePlayer == username ? 'white' : 'black'}
                  position={position}
                  onPieceDropExtension={makeMove}
                  lastMoveFen={previousFen}
                  arePremovesAllowed={playing}
                  colorTheme={chessboardColorTheme}
                  animationDuration={10}
                  restrictPiece={restrictPiece}
                  restrictedPiece={restrictedPiece}
                  // onPieceDragBegin={onPieceDragBegin}
                  // isDraggablePiece={isDraggablePiece}
                />
              )}
              {!(playing || started) && (
                <IdleStateGameBanner
                  // green
                  // #937ADB
                  // #7F62D1

                  // purple
                  // #3DAB9E
                  // #1E8175
                  StartColor={gamePlayMode == 'Play' ? '#3DAB9E' : '#937ADB'}
                  StopColor={gamePlayMode == 'Play' ? '#1E8175' : '#7F62D1'}
                  visible={
                    true
                    // !playing ||
                    // (thinkingTime && customRules.includes('thinking_time_rule'))
                  }
                />
              )}
              {isMobileOrTab && (started || playing) && (
                <PlayerComponent
                  flex={2}
                  color={whitePlayer == username ? 'white' : 'black'}
                  username={displayName}
                  timer={myTime}
                  whitePieces={whitePiecesCaptured}
                  blackPieces={blackPiecesCaptured}
                  firstMoveTimer={firstMoveTimer}
                  showFirstMoveTimer={showAbort && myTurn}
                  rating={myRating}
                  myTurn={myTurn}
                  playing={playing}
                />
              )}
              {isMobileOrTab && playing && (
                <BottomControlsComponent
                  showDrawRequest={showDrawRequest}
                  drawRequestSent={drawRequestSent}
                  drawRequest={drawRequest}
                  handleDrawResponse={handleDrawResponse}
                  resign={resign}
                  abort={abort}
                  showAbort={showAbort}
                  playing={playing}
                  showDrawReject={showDrawReject}
                  setShowDrawReject={setShowDrawReject}
                />
              )}
              <Box
                flexDirection="row"
                flexWrap="wrap"
                style={{width: '100%', justifyContent: 'space-evenly'}}>
                {!(playing || !ChallengeOpponent || !ChallengeMoveTimer) &&
                  started &&
                  !hint && (
                    <CCButton
                      onPress={() =>
                        challengeGame(
                          ChallengeOpponent,
                          ChallengeMoveTimer,
                          ChallengeIncrement,
                          ChallengeWhite,
                        )
                      }
                      disabled={
                        playing || !ChallengeOpponent || !ChallengeMoveTimer
                      }
                      flex={0.8}
                      style={{
                        backgroundColor: 'white',
                        borderColor: 'black',
                        borderWidth: 1,
                        width: 'auto',
                        marginTop: 12,
                      }}>
                      <img src={rematch} />
                      <CCText style={{color: 'black', marginLeft: 4}}>
                        Rematch
                      </CCText>
                    </CCButton>
                  )}
                {!(!MoveTimer || playing) &&
                  !showReviewGamesComponent &&
                  started &&
                  !hint && (
                    <CCButton
                      onPress={() => joinGame(MoveTimer, Increment)}
                      disabled={!MoveTimer || playing}
                      flex={0.8}
                      style={{
                        backgroundColor: 'white',
                        borderColor: 'black',
                        borderWidth: 1,
                        width: 'auto',
                        marginTop: 12,
                      }}>
                      <img src={rematch} />
                      <CCText style={{color: 'black', marginLeft: 4}}>
                        New {MoveTimer / 60000} + {Increment / 1000}
                      </CCText>
                    </CCButton>
                  )}
                {!playing &&
                  started &&
                  !showReviewGamesComponent &&
                  started &&
                  !hint && (
                    <CCButton
                      onPress={() => handle_new_game()}
                      flex={0.8}
                      style={{
                        backgroundColor: 'white',
                        borderColor: 'black',
                        borderWidth: 1,
                        width: 'auto',
                        marginTop: 12,
                      }}>
                      <img src={rematch} />
                      <CCText style={{color: 'black', marginLeft: 4}}>
                        New Game
                      </CCText>
                    </CCButton>
                  )}

                {!isMobileOrTab &&
                  playing &&
                  myTurn &&
                  thinkingTime &&
                  customRules.includes('thinking_time_rule') && (
                    <Box
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: CCColors.Red,
                        height: 40,
                      }}>
                      <CCText style={{color: 'white', marginLeft: 4}}>
                        Thinking time!
                      </CCText>
                    </Box>
                  )}
                {!isMobileOrTab && playing && assistedGameplay && hintMessage && (
                  <Box
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: CCColors.Red,
                      height: 'auto',
                    }}>
                    <CCText
                      style={{
                        color: 'white',
                        marginLeft: 6,
                        marginTop: 8,
                        marginBottom: 8,
                        marginRight: 6,
                      }}>
                      {hintMessage}
                    </CCText>
                  </Box>
                )}
              </Box>
              {isMobileOrTab &&
                playing &&
                myTurn &&
                thinkingTime &&
                customRules.includes('thinking_time_rule') && (
                  <Box>
                    <CCText style={{color: 'green', marginLeft: 4}}>
                      Thinking time!
                    </CCText>
                  </Box>
                )}
              {isMobileOrTab &&
                (playing || started) &&
                customRules.length > 0 && <GameRules rules={customRules} />}
            </Box>
          </Box>
        )}

        {/* Below is the right pane */}
        {/* When not playing */}
        {isStaff && !playing && !challengeInProgress && searching && (
          <SearchProgressComponent
            visible={searching}
            cancelSearch={cancelJoinGameRequest}
            started={foundOpponent}
            opponent={opponentDisplayName}
          />
        )}
        {isStaff &&
          !playing &&
          !challengeInProgress &&
          !searching &&
          !started && (
            <GameSearchComponent
              joinGame={joinGame}
              challengeGame={challengeGame}
              user={username}
              searchPlayers={searchPlayers}
              searchPlayersResult={searchPlayersResult}
              lastTimecontrol={MoveTimer / 60000 + '+' + Increment / 1000}
              showReviewGamesComponent={showReviewGamesComponent}
              customRules={customRules}
              setAssistedGameplay={setAssistedGameplay}
              setGameDetails={setGameDetails}
              isStaff={isStaffNew}
              setGamePlayMode={setGamePlayMode}
              enableLearn={enableLearn}
              gamePlayMode={gamePlayMode}
            />
          )}
        {isStaff && !playing && !searching && challengeInProgress && (
          <ChallengeProgressComponent
            visible={challengeInProgress}
            cancelSearch={handleChallengeCancel}
            opponent={ChallengeOpponent.split(':')[1]}
            gameDetails={`                   ${gameDetails}`}
            //   featureText={}
          />
        )}

        {isStaff && !isMobileOrTab && (playing || started) && (
          <Box
            flexDirection="column"
            flex={0.48}
            style={{
              height: '100%',
              overflow: 'visible',
              alignItems: 'center',
              marginLeft: '5%',
            }}>
            {/* <Box
              flexDirection="row"
              style={{alignItems: 'flex-end', marginBottom: 8}}>
              <CCText color="#FAF6EB" style={{marginRight: 18}}>
                Assisted Gameplay
              </CCText>
            </Box> */}
            <NotationComponent
              moves={isBlackTurnFirst ? [{}, ...moves] : moves}
              opponentTime={opponentTime}
              myTime={myTime}
              myTurn={myTurn}
              username={username}
              white={whitePlayer}
              displayName={displayName}
              opponent={opponentDisplayName}
              whitePieces={whitePiecesCaptured}
              blackPieces={blackPiecesCaptured}
              setPosition={setPosition}
              firstMoveTimer={firstMoveTimer}
              showFirstMoveTimer={showAbort && myTurn && playing}
              setMoveClicked={setMoveClicked}
              setMoveSelected={setMoveSelected}
              startfen={startfen}
              setPreviousFen={setPreviousFen}
              myRating={myRating}
              opponentRating={opponentRating}
              playing={playing}
              setHint={setHint}
              setHint2={setHint2}
              isBlackTurnFirst={isBlackTurnFirst}
              position={'relative'}
            />

            {hint && showIncorrectHintModal && (
              <Box
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 63,
                  right: 20,
                  zIndex: 1000,
                }}>
                <HintForIncorrectAttempt
                  infoiconcolor={CCColors.BlueAqua}
                  borderColor={CCColors.BlueAqua}
                  disabled={!isStaff}
                  padding={10}
                  title={'Engine Suggestion'}
                  width={'90%'}
                  result={hint}
                  result2={hint2}
                  setShowIncorrectHintModal={setShowIncorrectHintModal}
                  handleSubmit={handleSubmit}
                  showChange={showChange}
                  setShowChange={setShowChange}
                  goodFeedback={goodFeedback}
                  badFeedback={badFeedback}
                  setGoodFeedback={setGoodFeedback}
                  setBadFeedback={setBadFeedback}
                />
              </Box>
            )}
            <Box type="row-center-between" style={{width: '80%'}}>
              <Box>
                {playing && (
                  <BottomControlsComponent
                    showDrawRequest={showDrawRequest}
                    drawRequestSent={drawRequestSent}
                    drawRequest={drawRequest}
                    handleDrawResponse={handleDrawResponse}
                    resign={resign}
                    abort={abort}
                    showAbort={showAbort}
                    playing={playing}
                    showDrawReject={showDrawReject}
                    setShowDrawReject={setShowDrawReject}
                  />
                )}
                <Box
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: 12,
                    alignItems: 'center',
                  }}>
                  {!(!game_uuid || playing || Result == 'Abort') && (
                    <View style={[styles.cardHeading]}>
                      <CCButton
                        onPress={() => {
                          navigation.navigate(ScreenName.ChessAnalysis, {
                            game_id: game_uuid,
                          });
                        }}
                        disabled={!game_uuid || playing}
                        style={styles.reviewGameAction}
                        contentContainerStyle={{
                          paddingRight: 8,
                          paddingVertical: 8,
                          paddingLeft: 24,
                        }}>
                        <View
                          style={{
                            flex: 1,
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <CCText style={styles.reviewGameActionText}>
                            Review Game
                          </CCText>
                          <View
                            style={[
                              styles.reviewGameActionCoinsContainer,
                              {marginLeft: 8},
                            ]}>
                            <FontAwesome5
                              name="coins"
                              size={16}
                              color="#D1AB41"
                            />
                            <CCText style={{marginLeft: 8}}>50+ pts</CCText>
                          </View>
                        </View>
                      </CCButton>
                    </View>
                  )}
                </Box>
              </Box>
              <GameRules rules={customRules} />
            </Box>
          </Box>
        )}
        <Modal
          visible={searching && false}
          animationType="slide"
          transparent={true}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <View
              style={{backgroundColor: 'white', padding: 20, borderRadius: 10}}>
              <Text>Searching opponent .. </Text>
              <CCButton
                onPress={e => {
                  cancelJoinGameRequest();
                }}
                style={{margin: 4}}>
                Cancel Search
              </CCButton>
            </View>
          </View>
        </Modal>
        <Modal visible={gameState === 1} animationType="fade" transparent={true}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <View
              style={{backgroundColor: 'white', padding: 20, borderRadius: 10}}>
              <Text>Game to start in</Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  marginTop: 20,
                }}>
                <Text style={{fontSize: 24}}>{gameStartTime / 1000} s</Text>
              </View>
            </View>
          </View>
        </Modal>
        {/* <ChallengeComponent
          challenger={ChallengeOpponent}
          visible={showChallengeRequest}
          handleChallengeResponse={handleChallengeResponse}
          timeControl={
            (ChallengeMoveTimer / 60000).toString() +
            ' + ' +
            (ChallengeIncrement / 1000).toString()
          }
        /> */}
        <ResultComponent
          visible={showEndGameModal && gameplayStore?.showEndGameModal}
          result={Result}
          endGameMessage={endGameMessage}
          endType={endType}
          game_uuid={game_uuid}
          handle_end_game_modal={handle_end_game_modal}
          joinGame={joinGame}
          challengeGame={challengeGame}
          challenger={ChallengeOpponent}
          moveTimer={MoveTimer}
          Increment={Increment}
          challengeMoveTimer={ChallengeMoveTimer}
          challengeIncrement={ChallengeIncrement}
          white={ChallengeWhite}
          opponent={whitePlayer == username ? blackPlayer : whitePlayer}
          myRating={myRating}
          myRatingChange={myRatingChange}
          lastfen={startfen}
        />
        <ChallengeRejected
          Visible={showChallengeReject && gameplayStore?.showChallengeReject}
          message="Challenge Rejected!"
          handle_close={handle_challenge_reject}
        />
        <ChallengeRejected
          Visible={showChallengeExpire & gameplayStore?.showChallengeExpire}
          message="The challenge has expired!"
          handle_close={handle_challenge_reject}
        />
        <ChallengeRejected
          Visible={showChallengeCancel && gameplayStore?.showChallengeCancel}
          message="Challenge Cancelled!"
          handle_close={handle_challenge_reject}
        />
        <ChallengeRejected
          Visible={showPlayerNotFound}
          message="Players not available, Try again later!"
          handle_close={handle_challenge_reject}
        />

        

        {/* <Modal
          animationType="slide"
          transparent={true}
          visible={gameplayStore?.showChangeInClient}
          onRequestClose={handleClose}
        >
          <View style={connectionStyles.overlay}>
            <View style={connectionStyles.modalView}>
              <Text style={connectionStyles.modalText}>
                Connection detected on another tab. 
              </Text>
              <Text style={connectionStyles.modalText}>
                Do you want to continue playing on this tab or navigate to Dashboard and use other features?
              </Text>
              <View style={connectionStyles.buttonContainer}>
                <Button title= "Continue" onPress={handleContinue} />
                <Button title="Navigate to Dashboard" onPress={handleClose} color="red" />
              </View>
            </View>
          </View>
        </Modal> */}

        {!isStaff && <ComingSoon feature={'Game Play'} cover_img={2} />}
      </Box>
      <ConnectionModal
      visible={gameplayStore?.showChangeInClient && !isConnected} 
      onContinue={handleContinue}
      onClose={handleClose}
      />
    </Box>

  );
}

export default PlayChess;
