import {useQuery} from '@tanstack/react-query';
import ChessRestClient from '../../Api/ChessRestClient';

export function useGetStudyGamesDetail(game_id: string, handleOnSuccess) {
  const query = useQuery(
    ['studyGame', game_id],
    async () => {
      const response = await ChessRestClient.get(
        `/learning/study-games-detail/${game_id}`,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    },
  );

  return query;
}
