import React, {useRef, useEffect, useMemo} from 'react';
import {Animated, StyleSheet} from 'react-native';
import Box from '@/Atoms/Box';
import ConditionalRender from '@/Atoms/ConditionalRender';
import styles from './styles';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import CCColors from '@/Utils/CCColors';

export default function WinningBar({
  whiteWinRatio,
  blackWinRatio,
  reverse = false,
}: {
  whiteWinRatio: number;
  blackWinRatio: number;
  reverse?: boolean;
}) {
  const {isTablet, isMobile} = useMediaQuery();
  const whiteBarFlexValue = useRef(new Animated.Value(whiteWinRatio)).current;
  const blackBarFlexValue = useRef(new Animated.Value(blackWinRatio)).current;
  const chessboardColorTheme = useChessboardColorTheme();

  useEffect(() => {
    Animated.timing(whiteBarFlexValue, {
      toValue: whiteWinRatio,
      duration: 300,
      useNativeDriver: false,
    }).start();

    Animated.timing(blackBarFlexValue, {
      toValue: blackWinRatio,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [whiteWinRatio, blackWinRatio]);

  const barColor = useMemo(() => {
    if (chessboardColorTheme === 'green-white') {
      // lightColor = CCColors.ChessBoard.GreenLightColor;
      // darkColor = CCColors.ChessBoard.GreenDarkColor;
      // startColor = CCColors.ChessBoard.GreenStartColor;
      // endColor = CCColors.ChessBoard.GreenEndColor;
      return {
        blacksColor: CCColors.ChessBoard.GreenDarkColor,
        whitesColor: CCColors.ChessBoard.GreenLightColor,
      };
    } else if (chessboardColorTheme === 'blue-white') {
      return {
        blacksColor: CCColors.ChessBoard.BlueDarkColor,
        whitesColor: CCColors.ChessBoard.BlueLightColor,
      };
    }
  }, [chessboardColorTheme]);

  return (
    <Box
      style={
        isTablet || isMobile
          ? styles.winningBarContainerMobile
          : styles.winningBarContainer
      }>
      <ConditionalRender
        condition={reverse}
        childrenA={
          <>
            <Animated.View
              style={{
                ...styles.whiteBar,
                flex: whiteBarFlexValue,
                backgroundColor: barColor?.whitesColor,
              }}
            />
            <Animated.View
              style={{
                ...styles.blackBar,
                flex: blackBarFlexValue,
                backgroundColor: barColor?.blacksColor,
              }}
            />
          </>
        }
        childrenB={
          <>
            <Animated.View
              style={{
                ...styles.blackBar,
                flex: whiteBarFlexValue,
                backgroundColor: barColor?.blacksColor,
              }}
            />
            <Animated.View
              style={{
                ...styles.whiteBar,
                flex: blackBarFlexValue,
                backgroundColor: barColor?.whitesColor,
              }}
            />
          </>
        }
      />
    </Box>
  );
}
