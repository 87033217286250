export default {
  // API_URL: 'https://chess.circlechess.com',
  // GAME_SERVICE_URL: 'wss://gs.circlechess.com',
  GAME_SERVICE_URL: 'http://localhost:3000/',
  PAYMENT_API_URL: 'https://spayment.circlechess.com',
  CHAT_API_URL: 'https://schat.circlechess.com',
  CHESS_API_URL: 'https://staging.circlechess.com',
  ANALYZE_GAME_API_URL:
    'https://staging.circlechess.com/game/start_analyzing_game',
  DEBUG_ENABLED: true,
  API_KEY: '3c311b2d-f168-47c2-9d2a-de5c2df8677a',
};

