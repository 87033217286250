/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {Platform} from 'react-native';
import {DatePicker as NativeDatePicker} from '@react-native-community/datetimepicker';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';

const CCDatePicker = ({onChange, value}) => {
  // Get the user's local date and format it as 'YYYY-MM-DD' for the web picker
  const today = new Date().toLocaleDateString('en-CA'); // 'YYYY-MM-DD' format for HTML input

  // Function to format the selected date as 'YYYY-MM-DD'
  const formatDateForInput = date => {
    return date ? new Date(date).toLocaleDateString('en-CA') : '';
  };

  if (Platform.OS === 'web') {
    return (
      <Box
        style={{
          borderRadius: 10,
          borderColor: CCColors.Grey.Border,
          borderWidth: 1,
        }}>
        <input
          type="date"
          onChange={e => onChange(new Date(e.target.value))}
          value={formatDateForInput(value)} // Convert Date object to 'YYYY-MM-DD' for display
          style={{
            padding: 15,
            fontSize: 16,
            border: 'none',
          }}
          max={today} // Max date is today in user's timezone
        />
      </Box>
    );
  } else {
    return (
      <NativeDatePicker
        value={value}
        mode="date"
        display="default"
        onChange={(event, selectedDate) => onChange(selectedDate || value)}
        maximumDate={new Date()} // Maximum date is today's date in user's timezone
      />
    );
  }
};

export default CCDatePicker;
